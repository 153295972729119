export default {
  Shared: {
    Login: 'Log in',
    Add: 'Add',
    Edit: 'Edit',
    Query: 'Query',
    Submit: 'Submit',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    QuickLogin: 'Quick Login',
    PleaseChoose: 'Please Choose',
    PleaseEnter: 'Please Enter',
    Success: 'Success',
    Fail: 'Fail',
    Error: 'Error',
    Format: 'Format',
    DetailInfo: 'Detail Info',
    ChangePassword: 'Change Password',
    BindAccount: 'Account Bind',
    FacebookBind: 'Facebook Bind',
    BindReferrer: 'Bind Referrer',
    Account: 'Account',
    Password: 'Password',
    PhoneNumber: 'Phone Number',
    VerifyCode: 'Verify Code',
    ReSend: 'Re-Send',
    NewPassword: 'New Password',
    ConfirmPassword: 'Confirm Password',
    Deposit: 'Deposit',
    Withdraw: 'Withdraw',
    Tpp: '3rd-Party Payment',
    AutoDeposit: 'Auto Deposit',
    Records: 'Records',
    AgentBank: 'Agent Bank',
    MemberBank: 'Member Bank',
    Amount: 'Amount',
    Receipt: 'Receipt',
    Wallet: 'Wallet',
    Owner: 'Owner',
    All: 'All',
    Provider: 'Provider',
    GameType: 'GameType',
    Name: 'Name',
    Birthday: 'Birthday',
    IDCardNumber: 'ID Card Number',
    Verify: 'Verify',
    OTPVerifyCode: 'OTP Verify Code',
    Bank: 'Bank',
    BankAccount: 'Bank Account',
    BankPic: 'Bank Image',
    Email: 'Email',
    OriginalPassword: 'Original Password',
    ReferrerAccount: 'Referrer Account',
    RemainingFlow: 'Remaining Wager',
    Date: 'Date',
    Flow: 'Wager',
    Detail: 'Detail',
    Join: 'Join',
    Clear: 'Clear',
    Promotion: 'Promotion',
    DailyMission: 'Daily Mission',
    Collect: 'Collect',
    Finish: 'Finish',
    Invite: 'Invite',
    Copy: 'Copy',
    NoPromotion: 'No Promotion',
    NoMission: 'No Mission',
    NoAuthority: 'No Authority',
    GoToBindMember: 'Please complete your member infomation',
    Inbox: 'Inbox',
    Defined: 'Confirm',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    OK: 'OK',
    Advertisement: 'Advertisement',
    BettingRecord: 'Betting Record',
    Renew: 'Renew',
    Required: 'Required',
    RotateDeviceContent: 'Please Rotate Your Device For The Best Experience',
    Memo: 'Memo',
    SystemMail: 'Inbox',
    Contact: 'Contact',
    Bind: 'Bind',
    Music: 'Music',
    Language: 'Language',
    Version: 'Version',
    Logout: 'Logout',
    Maintenance: 'System under maintenance',
    Profile: 'Profile',
    Level: 'Level',
    PreAmount: 'Promotion Being Terminated, Witholding Amount',
    LanguageChangeMsg: 'Language is Change and going to reload page',
    NoData: 'No Data',
    AccumulatedWager: 'Accumulated Wager',
    PromotionThreshold: 'Promotion Threshold',
    Remaining1xWager: 'Remaining 1xWager',
    Install: 'Install',
    PromptMsg: "Get our free app. It won't take up space on your phone",
    SafariHint0: 'Open in Safari',
    SafariHint1: 'Tab on',
    SafariHint2: 'Select Add to Home Screen',
    Locked: 'Locked',
    TimeRemaining: 'Time Remaining',
    LeaderBoard: 'Leader Board',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    EndDate: 'End Date',
    Create: 'Create',
    GoBack: 'Go Back',
    SpecialOffer: 'Special Offer',
    PleaseEnterInSec: 'Please enter in {Sec} sec',
    MemberShipLevel: 'Member Level Info',
    Forever: 'Forever',
    Year: 'Year',
    DailyMissionReward: 'Daily Mission Reward',
    VIP2Info: 'Info Verified',
    VIP3Info: 'First Deposit',
    VIP4Info: 'Bet {Amount} In 2 Months',
    VIP5Info: 'Bet {Amount} In 2 Months',
    VIP6Info: 'Bet {Amount} In 2 Months',
    VIPDepositInfo: 'Deposit {Amount} In 2 Months',
    Activated: 'Activated',
    PlayGame: 'Play Game',
    Reward: 'Reward',
    Passbook: 'Passbook',
    DepositRecord: 'Deposit Record',
    WithdrawalRecord: 'Withdrawal Record',
    AgentCode: 'Referral Code',
    GameAccount: 'Game Account',
    JoinPromotion: 'Join Promotion',
    Spin: 'Spin',
    Congratulation: 'Congratulation',
    TicketNotEnough: 'Ticket not enough',
    RewardMsg: "You've got {num}",
    BounsWheel: 'Bonus Wheel',
    Yes: 'Yes',
    No: 'No',
    DiamondShop: 'Diamond Shop',
    GameRecord: 'Game Record',
    Setting: 'Setting',
    DiamondInsufficient: 'Diamond insufficient',
    FullScreenMode: 'Full Screen Mode',
    RewardPromotion: 'Promotion',
    RebatePromotion: 'Rebate',
    ReferrerPromotion: 'Refferal',
    DepositPromotion: 'Cash Back',
    LotteryPromotion: 'Lottery',
    '1xTurnover': '1x Turnover',
    AccumulatedWagerInfo: 'When the current accumulated promotion turnover meet the requirement,\n it can manually unlock the promotion. \n Threshold: {max}',
    NoLimit: 'No Limit',
    TotalBet: 'Total Bet',
    TotalDeposit: 'Total Deposit',
    VIPPrivileges: 'VIP Privileges',
    Requirement: 'Target',
    ReferralInfo: 'Referral Info',
    UserName: 'Username',
    CompanyProfit: 'Company Profit',
    Bonus: 'Bonus',
    SystemRecycle: 'Recycle',
    CurrentMonth: 'Current Month',
    PreviousMonth: 'Previous Month',
    FeedbackAmount: 'Payback Amount',
    MinBetAmount: 'Minimum bet:{Amount}',
    PromoCode: 'Promo Code',
    PrivatePolicy: 'Privacy Policy',
    VerifyInfo: 'Verify Info',
    Subscribe: 'Subscribe',
    A2HS: 'Add to Home Screen',
    Introduction: 'Introduction',
    Next: 'Next',
    WagerLimit: 'Turnover Limit',
    WagerLimitDesc: 'Including deposit turnover x1 and daily mission turnover',
    NetwinWagerDesc: 'The promotion can be finished when the accumulated netwin reach the threshold.\n Threshold: {max}',
    Quit: 'Quit',
    AskJoinAutoDepositPromotion: 'Do you wish to attend auto-deposit promotion',
    AskCancelJoinedPromotion: 'Do you wish to cancel auto-deposit promotion',
    Netwin: 'Netwin',
    ReferralCode: 'Referral code',
    ReferralQRCode: 'Referral QR code',
    EnterReferralCode: 'Enter referral code',
    ReferralCodeDoesNotExist: 'Referral code does not exist',
    ReferralByUser: 'Referral by {username}',
    NoPromotionItem: 'No items match the criteria'
  },
  CashOut: {
    Fields: {
      OrderNumber: 'Order Number',
      AuditAmount: 'Audit Amount',
      Status: 'Status',
      CreateTime: 'Create Time'
    }
  },
  CashIn: {
    Fields: {
      OrderNumber: 'Order Number',
      AuditAmount: 'Audit Amount',
      Status: 'Status',
      CreateTime: 'Create Time'
    }
  },
  Accounting: {
    Fields: {
      Source: 'Source',
      Type: 'Type',
      Quantity: 'Amount',
      CreateTime: 'Create Time'
    },
    Type: {
      1: 'Coin', // 金幣
      5: 'Diamond'
    },
    Passbook: {
      NoviceActivityReward: 'Novice Activity Reward',
      ReferrerActivityReward: 'Referrer Activity Reward',
      PromotionActivityReward: 'Promotion Activity Reward',
      FeedbackActivityReward: 'Feedback Activity Reward',
      SystemReward: 'System Reward',
      OtherReward: 'Other Reward',
      RecycleCategory: 'Recycle Category',
      DepositActivityReward: 'Deposit Activity Reward',
      DailyMissionReward: 'Daily Mission Reward',
      Lottery: 'Lottery Promotion Reward',
      DiamondMall: 'Shop Transaction',
      AbnormalOrderRecycle: 'Abnormal Order Recycle'
    }
  },
  BettingRecord: {
    Fields: {
      ProviderName: 'Provider',
      GameType: 'Game Type',
      Turnover: 'Turnover',
      Netwin: 'Netwin',
      BetTime: 'Bet Time'
    }
  },
  Message: {
    Msg: {
      HowToDeposit: 'How To Deposit',
      PleaseUploadImage: 'Please Upload Image',
      AmountLessThen: 'The amount cannot be less than {Amount}',
      AmountGreaterThen: 'The amount cannot be greater than {Amount}',
      AreYouPromotionFinished: 'Are you sure you want to finish ?',
      PleaseEnterCellPhone: 'Please Enter Cell Phone',
      DoYouWantToWithdraw: 'You Will Withdraw {Amount} {CurrencyName} Would you like to Withdraw?',
      DoYouWantToDeposit: 'You Will Deposit {Amount} {CurrencyName} Would you like to Deposit?',
      DoYouChangeLanguage: 'Would you like to change language?',
      VersionUpdated: 'Version Update',
      VersionUpdatedMsg: 'Version Updated, Page Refresh Now.',
      TokenExpired: 'Token Expired',
      TokenExpiredMsg: 'Your Token Already Expired，Please Re-login.',
      RepeatLogin: 'Repear Login',
      RepeatLoginMsg: 'Your Account Already Repeat Login，Please Re-login.',
      PromotionCheckMsg: 'Still in Promotion, Continue to Deposit?',
      RelievePromotion: 'Relieve Promotion',
      JoinDepositPromotionMsg: 'Join Deposit Promotion?',
      AgentCodeHint: 'Welcome to Ruai99, Please enter referral code or {code}@',
      AgentCodeHintG88: 'Please enter this code：{code}@',
      AccountFormat: '6~12 digits include English + number',
      PwdFormat: '6~12 digits include English + number',
      ConfirmPwd: 'Please enter password again',
      ConfirmToChange: 'Confirm to Exchange?',
      OTPMsg: 'OTP verification code send successfully.',
      PromotionVIPLevel: 'VIP{level} could join this promotion'
    },
    EnterAnyPress: 'Press Any Key',
    PleaseBindAccount: 'Please Bind Account',
    AutoDeposit: {
      1: 'MUST use the bank account that fulfilled when registration',
      2: 'Please use only ATM, Internet banking, or Mobile banking to deposit.',
      3: 'Deposit to the bank account on top (Click "Copy" to copy the bank account NO.)',
      4: 'After finish the deposit waits 1-3 minutes, the system will automatically add the credit.',
      5: "Due to is the bank website's maintenance time please avoiding deposit the money to KTB bank at 23:00PM-3: 00 AM.",
      6: 'Please inform our customer service team if you want to deposit the money',
      note1: 'In case of using the bank account which not the same as registered, the system will not add the credit or return the credit.',
      note2: 'For the member who use Auto deposit for first time, please go to member center to fulfill the personal information.'
    },
    AutoDepositContent: `1. MUST use the bank account that fulfilled when registration

    2. Please use only ATM, Internet banking, or Mobile banking to deposit.

    3. Deposit to the bank account on top (Click "Copy" to copy the bank account NO.)

    4. After finish the deposit waits 1-3 minutes, the system will automatically add the credit.


    5. Due to is the bank website's maintenance time please avoiding deposit the money to KTB bank at 23:00PM - 3:00 AM

    6. Please inform our customer service team if you want to deposit the money

    * In case of using the bank account which not the same as registered, the system will not add the credit or return the credit. *

    * For the member who use Auto deposit for first time, please go to member center to fulfill the personal information. *`,
    BindAccountMsg: "The account hasn’t been bound, If you log out, the account can't be retrieved. Do you want to bind the account?",
    PleaseEnterInSec: 'Please enter in {Sec} sec',
    PromotionReleaseTime: 'This process might take 3-5 mins to deactivate.',
    OTPPhoneNotSame: 'OTP does not match the mobile number, please get the verification code again.'
  },
  Member: {
    Info: {
      ReferrerAccount: 'Referrer Account'
    }
  },
  Setting: {
    Title: 'Settings'
  },
  Inbox: {
    tab: {
      System: 'System',
      Promotion: 'Promotion',
      Rewards: 'Rewards'
    }
  },
  Cash: {
    Status: {
      0: ' Establish Order', // 建立訂單
      1: ' In Review', // 審核中
      2: ' Review Successful', // 審核成功
      3: ' Review Refused', // 審核拒絕
      4: ' Transaction Cancelled' // 取消交易
    }
  },
  SystemMail: {
    tab: {
      System: 'System',
      Promotion: 'Promotion',
      Rewards: 'Rewards'
    }
  },
  ThirdPartPayment: {
    MinimumDeposit: 'Single payment minimum amount',
    MaximumDeposit: 'Single payment maximum amount',
    DailyLimit: 'Daily Limit',
    HandingFee: 'Handling Fee',
    NoHotKey: 'Cannot Participate Promotion By this Payment Method',
    Methods: {
      BANK: 'Bank Transfer',
      BANK_QRCODE: 'Bank QRcode',
      MOMO: 'MOMO PAY',
      ZALO: 'ZALO PAY',
      TNG: "Touch'n Go",
      WAVE_MONEY_TRANSFER: 'WaveMoney',
      DUITNOW_QRCODE: 'DuitNow',
      TRUE_MONEY_WALLET: 'TrueMoney',
      GCASH: 'GCash',
      GRABPAY: 'GrabPAY',
      ManualDeposit: 'Manual Deposit'
    },
    Fields: {
      Channel: 'Channel',
      Name: 'Name',
      Phone: 'Phone',
      TransactionID: 'Transaction No.',
      BankName: 'Bank Name',
      BankAccount: 'Bank Account',
      BankList: 'Please choose bank'
    }
  },
  DailyMission: {
    Mission: {
      Bet: '{Threshold} Turnover',
      Win: '{Threshold} Winnings',
      BetTimes: '{Threshold} Spins',
      DepositAmount: '{Threshold} Deposit',
      Referrer: '{Threshold} Referrer',
      AllGames: 'on all games',
      MultiGames: 'on multi-games',
      DailyLogin: 'Daily login'
    }
  },
  GameType: {
    'Slot Game': 'Slot',
    'Table Game': 'Table',
    'Card Game': 'Card',
    'Arcade Game Fisher': 'Fisher',
    'Arcade Game': 'Arcade',
    'Live Game': 'Live'
  }
}
