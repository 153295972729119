import API from '../../api'

const state = {
  Missions: {
    isLoading: false,
    data: {}
  },
  HasReward: {
    isLoading: false,
    data: {}
  },
  LongtermMission: {
    isLoading: false,
    data: {}
  }
}

const getters = {
  Missions: state => state.Missions,
  HasReward: state => state.HasReward,
  LongtermMission: state => state.LongtermMission
}

const mutations = {
  SET_MISSIONS: (state, data) => {
    state.Missions = data
  },
  SET_HASREWARD: (state, data) => {
    state.HasReward = data
  },
  SET_LONGTERM_MISSION: (state, data) => {
    state.LongtermMission = data
  }
}

const actions = {
  getMissions: async ({ commit, state }) => {
    return new Promise(async (resolve, reject) => {
      if (state.Missions.isLoading) {
        console.warn('DailyMission讀取中')
        return reject(new Error('DailyMission is loading'))
      }
      commit('SET_MISSIONS', { ...state.Missions, isLoading: true })
      const res = await API.Activity.Get_DailyMissionList().catch((err) => {
        console.log(err)
        commit('SET_MISSIONS', { ...state.Missions, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_MISSIONS', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_MISSIONS', { ...state.Missions, isLoading: false })
        return reject(new Error('Get daily mission error'))
      }
    })
  },
  getHasReward({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.HasReward.isLoading) {
        console.warn('DailyMission Reward讀取中')
        return reject(new Error('DailyMission reward is loading'))
      }
      commit('SET_HASREWARD', { ...state.HasReward, isLoading: true })
      const res = await API.Activity.GetHasDailyReward().catch((err) => {
        console.log(err)
        commit('SET_HASREWARD', { ...state.HasReward, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_HASREWARD', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_HASREWARD', { ...state.HasReward, isLoading: false })
        return reject(new Error('get DailyMission reward error'))
      }
    })
  },
  getLongtermMission({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.LongtermMission.isLoading) {
        console.warn('LongtermMission Reward讀取中')
        return reject('error')
      }

      commit('SET_LONGTERM_MISSION', { ...state.LongtermMission, isLoading: true })
      const res = await API.Activity.Get_LongtermMission().catch(error => {
        console.log(error)
        return null
      })

      if (res?.ErrorCode === 0) {
        commit('SET_LONGTERM_MISSION', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_LONGTERM_MISSION', { ...state.LongtermMission, isLoading: false })
        return reject('error')
      }
    })
  },
  getAllData({ dispatch }) {
    dispatch('getMissions')
    dispatch('getHasReward')
    dispatch('getLongtermMission')
  },
  resetLoading({ commit, state }) {
    commit('SET_MISSIONS', { ...state.Missions, isLoading: false })
    commit('SET_HASREWARD', { ...state.HasReward, isLoading: false })
    commit('SET_LONGTERM_MISSION', { ...state.LongtermMission, isLoading: false })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
