import language from './language'
import API from './api'
import VueModule from './vue-module'

export { default as language } from './language'
export { default as API } from './api'
export { default as VueModule } from './vue-module'
export default {
  language,
  API,
  VueModule
}