export default {
  Shared: {
    Login: 'Đăng Nhập',
    Add: 'Thêm Vào',
    Edit: 'Chỉnh Sửa',
    Query: 'Truy Vấn',
    Submit: 'Gửi',
    Register: 'Đăng Ký',
    ForgotPassword: 'Quên Mật Khẩu',
    QuickLogin: 'Đăng Nhập Nhanh',
    PleaseChoose: 'Xin Vui Lòng Chọn',
    PleaseEnter: 'Vui Lòng Nhập',
    Success: 'Thành Công',
    Fail: 'Thất Bại',
    Error: 'Lỗi',
    Format: 'Sắp Xếp',
    DetailInfo: 'Thông Tin Chi Tiết',
    ChangePassword: 'Đổi Mật Khẩu',
    BindAccount: 'Tài Khoản Ràng Buộc',
    FacebookBind: 'Liên Kết Facebook',
    BindReferrer: 'Liên Kết Giới Thiệu',
    Account: 'Tài Khoản',
    Password: 'Mật Khẩu',
    PhoneNumber: 'Số Điện Thoại',
    VerifyCode: 'Mã Xác Minh',
    ReSend: 'Gửi Lại',
    NewPassword: 'Mật Khẩu Mới',
    ConfirmPassword: 'Xác Nhận Mật Khẩu',
    Deposit: 'Nạp Tiền',
    Withdraw: 'Rút Tiền',
    Tpp: 'Thanh Toán Khác',
    AutoDeposit: 'Nạp Tiền Tự Động',
    Records: 'Hồ Sơ',
    AgentBank: 'Ngân Hàng Đại Lý',
    MemberBank: 'Ngân Hàng Thành Viên',
    Amount: 'Số Lượng',
    Receipt: 'Biên Lai',
    Wallet: 'Ví Tiền',
    Owner: 'Chủ Nhân',
    All: 'Tất Cả',
    Provider: 'Các Nhà Cung Cấp',
    GameType: 'Kiểu Trò Chơi',
    Name: 'Tên',
    Birthday: 'Sinh Nhật',
    IDCardNumber: 'Số Thẻ ID',
    Verify: 'Xác Nhận',
    OTPVerifyCode: 'Mã Xác Minh OTP',
    Bank: 'Ngân Hàng',
    BankAccount: 'Tài Khoản Ngân Hàng',
    BankPic: 'Hình Ảnh Ngân Hàng',
    Email: 'Email',
    OriginalPassword: 'Mật Khẩu Ban Đầu',
    ReferrerAccount: 'Tài Khoản Người Giới Thiệu',
    RemainingFlow: 'Tiền Cược Còn Lại',
    Date: 'Ngày',
    Flow: 'Cuộc Chiến',
    Detail: 'Chi Tiết',
    Join: 'Tham Gia',
    Clear: 'Làm Mới',
    Promotion: 'Khuyến Mãi',
    DailyMission: 'Nhiệm Vụ Hàng Ngày',
    Collect: 'Sưu Tầm',
    Finish: 'Kết Thúc',
    Invite: 'Mời Gọi',
    Copy: 'Sao Chép',
    NoPromotion: 'Không Có Khuyến Mãi',
    NoMission: 'Không Có Nhiệm Vụ',
    NoAuthority: 'Không Có Thẩm Quyền',
    GoToBindMember: 'Vui Lòng Điền Thông Tin Thành Viên Của Bạn',
    Inbox: 'Hộp Thư Đến',
    Defined: 'Xác Nhận',
    Cancel: 'Hủy Bỏ',
    Confirm: 'Xác Nhận',
    OK: 'OK',
    Advertisement: 'Quảng Cáo',
    BettingRecord: 'Hồ Sơ Cá Cược',
    Renew: 'Thay Mới',
    Required: 'Yêu Cầu',
    RotateDeviceContent: 'Vui Lòng Xoay Thiết Bị Để Có Trải Nghiệm Tốt Nhất',
    Memo: 'Bản Ghi Nhớ',
    SystemMail: 'Thư hệ thốngv',
    Contact: 'Liên Hệ',
    Bind: 'Ràng Buộc',
    Music: 'Âm Nhạc',
    Language: 'Ngôn Ngữ',
    Version: 'Phiên Bản',
    Logout: 'Đăng Xuất',
    Maintenance: 'Hệ Thống Đang Được Bảo Trì',
    Profile: 'Hồ Sơ',
    Level: 'Cấp Độ',
    PreAmount: 'Khuyến Mãi Chấm Dứt , Số Tiền Giữ Lại',
    LanguageChangeMsg: 'Thay Đổi Ngôn Ngữ Và Sẽ Tải Lại Trang',
    NoData: 'Không Có Dữ Liệu',
    AccumulatedWager: 'Tiền Cược Tích Lũy',
    PromotionThreshold: 'Ngưỡng Khuyến Mãi',
    Remaining1xWager: 'Còn Lại 1x Tiền Cược',
    Install: 'Cài Đặt',
    PromptMsg: 'Tải App Miễn Phí Của Chúng Tôi , Sẽ Không Làm Bạn Thất Vọng',
    SafariHint0: 'Mở Trong Trình Duyệt Safari',
    SafariHint1: 'Bật Tab',
    SafariHint2: 'Chọn Thêm Vào Màn Hình Chính',
    Locked: 'Đã Khóa',
    TimeRemaining: 'Thời Gian Còn Lại',
    LeaderBoard: 'Ban Lãnh Đạo',
    Daily: 'Hằng Ngày',
    Weekly: 'Hằng Tuần',
    Monthly: 'Hằng Tháng',
    EndDate: 'Ngày Cuối',
    Create: 'Tạo Ra',
    GoBack: 'Quay Lại',
    SpecialOffer: 'Đề Nghị Đặc Biệt',
    PleaseEnterInSec: 'Vui Lòng Nhập Sau {Sec} Giây',
    MemberShipLevel: 'Thông Tin Cấp Độ Thành Viên',
    Forever: 'Mãi Mãi',
    Year: 'Năm',
    DailyMissionReward: 'Phần Thưởng Nhiêm Vụ Hằng Ngày',
    VIP2Info: 'Thông Tin Đã Được Xác Minh',
    VIP3Info: 'Nạp Tiền Lần Đầu',
    VIP4Info: 'Đặt Cược {Amount} Trong 2 Tháng',
    VIP5Info: 'Đặt Cược {Amount} Trong 2 Tháng',
    VIP6Info: 'Đặt Cược {Amount} Trong 2 Tháng',
    VIPDepositInfo: 'Nạp Tiền {Amount} Trong 2 Tháng',
    Activated: 'Đã Kích Hoạt',
    PlayGame: 'Chơi Game',
    Reward: 'Giải Thưởng',
    Passbook: 'Passbook',
    DepositRecord: 'Deposit Record',
    WithdrawalRecord: 'Withdrawal Record',
    AgentCode: 'Referral Code',
    GameAccount: 'Game Account',
    JoinPromotion: 'Join Promotion',
    Spin: 'Spin',
    Congratulation: 'Congratulation',
    TicketNotEnough: 'Ticket not enough',
    RewardMsg: "You've got {num}",
    BounsWheel: 'Bonus Wheel',
    Yes: 'Yes',
    No: 'No',
    DiamondShop: 'Diamond Shop',
    GameRecord: 'Game Record',
    Setting: 'Setting',
    DiamondInsufficient: 'Diamond insufficient',
    FullScreenMode: 'Full Screen Mode',
    RewardPromotion: 'Reward Promotion',
    RebatePromotion: 'Rebate Promotion',
    ReferrerPromotion: 'Referrer Promotion',
    DepositPromotion: 'Deposit Promotion',
    LotteryPromotion: 'Lottery Promotion',
    '1xTurnover': '1x Turnover',
    AccumulatedWagerInfo: 'When the current accumulated promotion turnover meet the requirement,\n it can manually unlock the promotion. \n Threshold: {max}',
    NoLimit: 'No Limit',
    TotalBet: 'Total Bet',
    TotalDeposit: 'Total Deposit',
    VIPPrivileges: 'VIP Privileges',
    Requirement: 'Target',
    ReferralInfo: 'Referral Info',
    UserName: 'Username',
    CompanyProfit: 'Company Profit',
    Bonus: 'Bonus',
    SystemRecycle: 'Recycle',
    CurrentMonth: 'Current Month',
    PreviousMonth: 'Previous Month',
    FeedbackAmount: 'Payback Amount',
    MinBetAmount: 'Minimum bet:{Amount}',
    PromoCode: 'Promo Code',
    PrivatePolicy: 'Chính sách bảo mật',
    VerifyInfo: 'Verify Info',
    Subscribe: 'Subscribe',
    A2HS: 'Add to Home Screen',
    Introduction: 'Introduction',
    Next: 'Next',
    WagerLimit: 'Turnover Limit',
    WagerLimitDesc: 'Including deposit turnover x1 and daily mission turnover',
    NetwinWagerDesc: 'The promotion can be finished when the accumulated netwin reach the threshold.\n Threshold: {max}',
    Quit: 'Quit',
    AskJoinAutoDepositPromotion: 'Do you wish to attend auto-deposit promotion',
    AskCancelJoinedPromotion: 'Do you wish to cancel auto-deposit promotion',
    Netwin: 'Netwin',
    ReferralCode: 'Referral code',
    ReferralQRCode: 'Referral QR code',
    EnterReferralCode: 'Enter referral code',
    ReferralCodeDoesNotExist: 'Referral code does not exist',
    ReferralByUser: 'Referral by {username}',
    NoPromotionItem: 'No items match the criteria'
  },
  CashOut: {
    Fields: {
      OrderNumber: 'Số Đơn Hàng',
      AuditAmount: 'Số Tiền Kiểm Toán',
      Status: 'Trạng Thái',
      CreateTime: 'Thêm Thời Gian'
    }
  },
  CashIn: {
    Fields: {
      OrderNumber: 'Số Đơn Hàng',
      AuditAmount: 'Số Tiền Kiểm Toán',
      Status: 'Trạng Thái',
      CreateTime: 'Thêm Thời Gian'
    }
  },
  Accounting: {
    Fields: {
      Source: 'Nguồn',
      Type: 'Kiểu',
      Quantity: 'Số Lượng',
      CreateTime: 'Thêm Thời Gian'
    },
    Type: {
      1: 'Đồng Xu', // 金幣
      5: 'Diamond'
    },
    Passbook: {
      NoviceActivityReward: 'Phần Thưởng Dành Cho Thành Viên Mới',
      ReferrerActivityReward: 'Phần Thưởng Dành Cho Người Giới Thiệu',
      PromotionActivityReward: 'Phần Thưởng Cho Hoạt Động Quảng Cáo',
      FeedbackActivityReward: 'Phần Thưởng Dành Cho Ý Kiến Bình Luận',
      SystemReward: 'Phần Thưởng Hệ Thống',
      OtherReward: 'Phần Thưởng Khác',
      RecycleCategory: 'Danh Mục Phục Hồi',
      DepositActivityReward: 'Phần Thưởng Dành Cho Nạp Tiền',
      DailyMissionReward: 'Phần Thưởng Nhiêm Vụ Hằng Ngày',
      Lottery: 'Lottery Promotion Reward',
      DiamondMall: 'Shop Transaction',
      AbnormalOrderRecycle: 'Abnormal Order Recycle'
    }
  },
  BettingRecord: {
    Fields: {
      ProviderName: 'Các Nhà Cung Cấp',
      GameType: 'Loại Trò Chơi',
      Turnover: 'Doanh Thu',
      Netwin: 'Chiến Thắng',
      BetTime: 'Thời Gian Đặt Cược'
    }
  },
  Message: {
    Msg: {
      HowToDeposit: 'Cách Nạp Tiền',
      PleaseUploadImage: 'Vui Lòng Tải Hình Ảnh Lên',
      AmountLessThen: 'Số Tiền Không Được Nhỏ Hơn {Amount}',
      AmountGreaterThen: 'Số Tiền Không Được Lớn Hơn {Amount}',
      AreYouPromotionFinished: 'Bạn Chắc Chắn Muốn Hoàn Thành Không ?',
      PleaseEnterCellPhone: 'Vui Lòng Nhập Số Điện Thoại',
      DoYouWantToWithdraw: 'Bạn sẽ rút tiền {Amount} {CurrencyName} Bạn Có Muốn Rút Tiền Không?',
      DoYouWantToDeposit: 'Bạn sẽ nạp tiền {Amount} {CurrencyName} Bạn Có Muốn Nạp Tiền Không?',
      DoYouChangeLanguage: 'Bạn Có Muốn Thay Đổi Ngôn Ngữ Không ??',
      VersionUpdated: 'Version Update',
      VersionUpdatedMsg: 'Version Updated, Page Refresh Now.',
      TokenExpired: 'Token Expired',
      TokenExpiredMsg: 'Your Token Already Expired，Please Re-login.',
      RepeatLogin: 'Repear Login',
      RepeatLoginMsg: 'Your Account Already Repeat Login，Please Re-login.',
      PromotionCheckMsg: 'Still in Promotion, Continue to Deposit?',
      RelievePromotion: 'Relieve Promotion',
      JoinDepositPromotionMsg: 'Join Deposit Promotion?',
      AccountFormat: '6~12 digits include English + number',
      AgentCodeHint: 'Welcome to Ruai99, Please enter referral code or {code}@',
      AgentCodeHintG88: 'Please enter this code：{code}@',
      PwdFormat: '6~12 digits include English + number',
      ConfirmPwd: 'Please enter password again',
      ConfirmToChange: 'Confirm to Exchange?',
      OTPMsg: 'OTP verification code send successfully.',
      PromotionVIPLevel: 'VIP{level} could join this promotion'
    },
    EnterAnyPress: 'Nhấn Nút Bất Kỳ',
    PleaseBindAccount: 'Vui Lòng Ràng Buộc Tài Khoản',
    AutoDeposit: {
      1: 'Phải Sử Dụng Tài Khoản Ngân Hàng Khi Đăng Ký',
      2: 'Vui Lòng Sử Dụng Tài Khoản Ngân Hàng ATM banking Để Nạp Tiền',
      3: "Gửi Tiền Vào Tài Khoản Ngân Hàng Ở Trên Cùng ( Nhấp Vào 'sao chép' Để sao chép số tài khoản ngân hàng )",
      4: 'Sau Khi Nạp Tiền Xong , Vui Lòng Chờ 1 _ 3 Phút Hệ Thống Tự Động Lên Điểm',
      5: 'Do Thời Gian Bảo Trì Trang Web Của Ngân Hàng , Vui Lòng Không Gửi Tiền vào Ngân hàng KTB tại 23',
      6: 'Vui Lòng Liên Hệ Dịch Vụ Của Chúng Tôi Nếu Bạn Muốn Nạp Tiền',
      note1: 'Tài Khoản Ngân Hàng Không Giống Với Tài Khoản Đăng Ký , Hệ Thống Sẽ Không Cộng Điểm Hoặc Trả Lại Tiền',
      note2: 'Đối Với Thành Viên Nạp Tiền Lần Đầu , Vui Lòng Đến Trung Tâm Thành Viên Điền Đầy Đủ Thông Tin Cá Nhân'
    },
    AutoDepositContent: `1. Phải Sử Dụng Tài Khoản Ngân Hàng Khi Đăng Ký

    2. Vui Lòng Sử Dụng Tài Khoản Ngân Hàng ATM banking Để Nạp Tiền

    3. Gửi Tiền Vào Tài Khoản Ngân Hàng Ở Trên Cùng ( Nhấp Vào 'sao chép' Để sao chép số tài khoản ngân hàng )

    4. Sau Khi Nạp Tiền Xong , Vui Lòng Chờ 1 _ 3 Phút Hệ Thống Tự Động Lên Điểm

    5. Do Thời Gian Bảo Trì Trang Web Của Ngân Hàng , Vui Lòng Không Gửi Tiền vào Ngân hàng KTB tại 23

    6. Vui Lòng Liên Hệ Dịch Vụ Của Chúng Tôi Nếu Bạn Muốn Nạp Tiền

    * Tài Khoản Ngân Hàng Không Giống Với Tài Khoản Đăng Ký , Hệ Thống Sẽ Không Cộng Điểm Hoặc Trả Lại Tiền *

    * Đối Với Thành Viên Nạp Tiền Lần Đầu , Vui Lòng Đến Trung Tâm Thành Viên Điền Đầy Đủ Thông Tin Cá Nhân *`,
    BindAccountMsg: 'Tài Khoản Chưa Bị Ràng Buộc Nếu Bạn Đăng Xuất , Tài Khoản Sẽ Không Lưu Lại Được . Bạn Có Muốn Ràng Buộc Tài Khoản Không ?',
    PleaseEnterInSec: 'Vui Lòng Nhập Sau {Sec} Giây',
    PromotionReleaseTime: 'This process might take 3-5 mins to deactivate.',
    OTPPhoneNotSame: 'OTP does not match the mobile number, please get the verification code again.'
  },
  Member: {
    Info: {
      ReferrerAccount: 'Tài Khoản Người Giới Thiệu'
    }
  },
  Setting: {
    Title: 'Cài Đặt'
  },
  Inbox: {
    tab: {
      System: 'Hệ Thống',
      Promotion: 'Khuyến Mãi',
      Rewards: 'Phần Thưởng'
    }
  },
  Cash: {
    Status: {
      0: ' Thiết Lập Đơn Hàng', // 建立訂單
      1: ' Đang Đánh Giá', // 審核中
      2: ' Đánh Giá Thành Công', // 審核成功
      3: ' Đánh giá bị Từ chối', // 審核拒絕
      4: ' Giao Dịch Bị Hủy Bỏ' // 取消交易
    }
  },
  SystemMail: {
    tab: {
      System: 'Hệ Thống',
      Promotion: 'Khuyến Mãi',
      Rewards: 'Phần Thưởng'
    }
  },
  ThirdPartPayment: {
    MinimumDeposit: 'Số Tiền Nạp Tối Thiểu 1 Lần',
    MaximumDeposit: 'Số Tiền Nạp Tối Đa 1 Lần',
    DailyLimit: 'Giới Hạn Hàng Ngày',
    HandingFee: 'Phí Xử Lý',
    NoHotKey: 'Không Thể Tham Gia Khuyến Mãi Qua Phương Thức Thanh Toán Này',
    Methods: {
      BANK: 'Bank Transfer',
      BANK_QRCODE: 'Bank QRcode',
      MOMO: 'MOMO PAY',
      ZALO: 'ZALO PAY',
      TNG: "Touch'n Go",
      WAVE_MONEY_TRANSFER: 'WaveMoney',
      DUITNOW_QRCODE: 'DuitNow',
      TRUE_MONEY_WALLET: 'TrueMoney',
      GCASH: 'GCash',
      GRABPAY: 'GrabPAY',
      ManualDeposit: 'Nạp Tiền'
    },
    Fields: {
      Channel: 'Channel',
      Name: 'Tên',
      Phone: 'Số Điện Thoại',
      TransactionID: 'Transaction No.',
      BankName: 'Bank Name',
      BankAccount: 'Bank Account',
      BankList: 'Please choose bank'
    }
  },
  DailyMission: {
    Mission: {
      Bet: '{Threshold} Doanh Thu',
      Win: '{Threshold} Tiền Thắng',
      BetTimes: '{Threshold} Lượt Quay',
      DepositAmount: '{Threshold} Tiền Nạp',
      Referrer: '{Threshold} Người Giới Thiệu',
      AllGames: 'Trên Tất Cả Các Trò Chơi',
      MultiGames: 'Trên Nhiều Trò Chơi',
      DailyLogin: 'Đăng Nhập Hằng Ngày'
    }
  },
  GameType: {
    'Slot Game': 'Slot',
    'Table Game': 'Trò Chơi Trên Bàn',
    'Card Game': 'Game Bài',
    'Arcade Game Fisher': 'Bắn Cá',
    'Arcade Game': 'Giải Trí',
    'Live Game': 'Live'
  }
}

