export default {
  Shared: {
    Login: '登入',
    Add: '新增',
    Edit: '编辑',
    Query: '查询',
    Submit: '提交',
    Register: '注册',
    ForgotPassword: '忘记密码',
    QuickLogin: '快登',
    PleaseChoose: '请选择',
    PleaseEnter: '请输入',
    Success: '成功',
    Fail: '失败',
    Error: '错误',
    Format: '格式',
    DetailInfo: '会员资讯',
    ChangePassword: '更改密码',
    BindAccount: '绑定帐号',
    FacebookBind: '绑定Facebook',
    BindReferrer: '绑定推荐人',
    Account: '帐号',
    Password: '密码',
    PhoneNumber: '电话号码',
    VerifyCode: '验证码',
    ReSend: '重发',
    NewPassword: '新密码',
    ConfirmPassword: '确认密码',
    Deposit: '存款',
    Withdraw: '提款',
    Tpp: '三方支付',
    AutoDeposit: '自动上分',
    Records: '交易纪录',
    AgentBank: '代理银行',
    MemberBank: '会员银行',
    Amount: '金额',
    Receipt: '收据',
    Wallet: '钱包',
    Owner: '拥有者',
    All: '全部',
    Provider: '提供者',
    GameType: '游戏类型',
    Name: '姓名',
    Birthday: '生日',
    IDCardNumber: '身分证',
    Verify: '验证',
    OTPVerifyCode: 'OTP验证码',
    Bank: '银行',
    BankAccount: '银行帐号',
    BankPic: '银行图片',
    Email: '电子信箱',
    OriginalPassword: '原始的密码',
    ReferrerAccount: '推荐人帐号',
    RemainingFlow: '剩馀流水',
    Date: '日期',
    Flow: '流水',
    Detail: '细节',
    Join: '参加',
    Clear: '清除',
    Promotion: '活动',
    DailyMission: '每日任务',
    Collect: '收集',
    Finish: '完成',
    Invite: '邀请',
    Copy: '复制',
    NoPromotion: '没有活动',
    NoMission: '没有任务',
    NoAuthority: '尚无权限',
    GoToBindMember: '前往绑定会员',
    Inbox: '收件箱',
    Defined: '确定',
    Cancel: '取消',
    Confirm: '确认',
    OK: 'OK',
    Advertisement: '广告',
    BettingRecord: '下注纪录',
    Renew: '重捞',
    Required: '必填',
    RotateDeviceContent: '为确保功能正常，且让您有最好的使用体验，请将您的萤幕旋转为横向，谢谢！',
    Memo: '注记',
    SystemMail: '系统信',
    Contact: '联系',
    Bind: '绑定',
    Music: '音乐',
    Language: '语言',
    Version: '版本',
    Logout: '登出',
    Maintenance: '维护中',
    Profile: '个人信息',
    Level: '等级',
    PreAmount: '活动解除中，预扣金额',
    LanguageChangeMsg: '语系切换,即将重整页面',
    NoData: '无资料',
    AccumulatedWager: '累积流水',
    PromotionThreshold: '活动门槛',
    Remaining1xWager: '剩馀一倍流水',
    Install: '安装',
    PromptMsg: '取得我们的免费 APP, 它不会耗费太多你的手机空间',
    SafariHint0: '使用 Safari 開啟頁面',
    SafariHint1: '点击',
    SafariHint2: '选择 添加到主屏幕',
    Locked: '已锁定',
    TimeRemaining: '剩馀时间',
    LeaderBoard: '龙虎榜',
    Daily: '每日',
    Weekly: '每周',
    Monthly: '每月',
    EndDate: '結束日期',
    Create: '建立',
    GoBack: '返回',
    SpecialOffer: '超级促销',
    MemberShipLevel: '会员等级说明',
    Forever: '永久',
    Year: '年',
    DailyMissionReward: '每日任务奖励',
    VIP2Info: '绑定会员资讯',
    VIP3Info: '首储',
    VIP4Info: '两个月内下注 {Amount}',
    VIP5Info: '两个月内下注 {Amount}',
    VIP6Info: '两个月内下注 {Amount}',
    VIPDepositInfo: '两个月内存款 {Amount}',
    Activated: '已达成',
    PlayGame: '玩游戏',
    Reward: '奖励',
    Passbook: '存折',
    DepositRecord: '存款记录',
    WithdrawalRecord: '提款记录',
    AgentCode: '推荐码',
    GameAccount: '游戏帐号',
    JoinPromotion: '参加活动',
    Spin: '旋转',
    Congratulation: '恭喜',
    TicketNotEnough: '票券不足',
    RewardMsg: '您已获得 {num}',
    BounsWheel: '幸运轮盘',
    Yes: '是',
    No: '否',
    DiamondShop: '钻石商城',
    GameRecord: '游戏纪录',
    Setting: '设定',
    DiamondInsufficient: '钻石不足',
    FullScreenMode: '即将进入全屏模式',
    RewardPromotion: '优惠活动',
    RebatePromotion: '返水活动',
    ReferrerPromotion: '推荐人活动',
    DepositPromotion: '存款赠活动',
    LotteryPromotion: '抽奖活动',
    '1xTurnover': '一倍流水',
    AccumulatedWagerInfo: '目前累积活动流水，满足活动门槛即可解除活动， \n 活动门槛：{max}',
    NoLimit: '无限制',
    TotalBet: '总下注',
    TotalDeposit: '总存款',
    VIPPrivileges: 'VIP特权',
    Requirement: '达成条件',
    ReferralInfo: '推荐人资讯',
    UserName: '帐号',
    CompanyProfit: '公司利润',
    Bonus: '赠金',
    SystemRecycle: '系统回收',
    CurrentMonth: '当月',
    PreviousMonth: '前月',
    FeedbackAmount: '回馈金额',
    MinBetAmount: '最低投注额: {Amount}',
    PromoCode: '优惠码',
    PrivatePolicy: '隐私权政策',
    VerifyInfo: '验证身分',
    Subscribe: '订阅通知',
    A2HS: '添加到主屏幕',
    Introduction: '教学',
    Next: '下一步',
    WagerLimit: '流水限制',
    WagerLimitDesc: '包含存款一倍流水與每日任務獎勵流水',
    NetwinWagerDesc: '累積贏分達到門檻即可解除活動\n門檻：{max}',
    Quit: '离开',
    AskJoinAutoDepositPromotion: '是否参加自动上分存款赠活动',
    AskCancelJoinedPromotion: '是否取消参加自动上分存款赠活动',
    Netwin: '输赢',
    ReferralCode: '推荐码',
    ReferralQRCode: '推荐二维码',
    EnterReferralCode: '输入推荐码',
    ReferralCodeDoesNotExist: '推荐码不存在',
    ReferralByUser: '{username} 推荐',
    NoPromotionItem: '没有符合条件的项目'
  },
  CashOut: {
    Fields: {
      OrderNumber: '订单编号',
      AuditAmount: '金额',
      Status: '状态',
      CreateTime: '建立时间'
    }
  },
  CashIn: {
    Fields: {
      OrderNumber: '订单编号',
      AuditAmount: '金额',
      Status: '状态',
      CreateTime: '建立时间'
    }
  },
  Accounting: {
    Fields: {
      Source: '来源',
      Type: '类型',
      Quantity: '数量',
      CreateTime: '建立时间'
    },
    Type: {
      1: '金币',
      5: '钻石'
    },
    Passbook: {
      NoviceActivityReward: '新手活动奖励',
      ReferrerActivityReward: '推荐活动奖励',
      PromotionActivityReward: '优惠活动奖励',
      FeedbackActivityReward: '返水活动奖励',
      SystemReward: '系统奖励',
      OtherReward: '额外奖励',
      RecycleCategory: '活动奖金回收',
      DepositActivityReward: '超级促销赠金',
      DailyMissionReward: '每日任务赠金',
      Lottery: '抽奖活动奖励',
      DiamondMall: '商城交易',
      AbnormalOrderRecycle: '异常注单回收'
    }
  },
  BettingRecord: {
    Fields: {
      ProviderName: '提供者名称',
      GameType: '游戏类型',
      Turnover: '投注',
      Netwin: '输赢',
      BetTime: '下注时间'
    }
  },
  Message: {
    Msg: {
      HowToDeposit: '如何存款',
      PleaseUploadImage: '请上传图片',
      AmountLessThen: '金额不得少于 {Amount}',
      AmountGreaterThen: '金额不能大于 {Amount}',
      AreYouPromotionFinished: '您确定要完成吗?',
      PleaseEnterCellPhone: '请填手机号码',
      DoYouWantToWithdraw: '您将提取 {Amount} {CurrencyName} 您要提款吗?',
      DoYouWantToDeposit: '您将储值 {Amount} {CurrencyName} 您要储值吗?',
      DoYouChangeLanguage: '你想改变语言吗?',
      VersionUpdated: '版本更新',
      VersionUpdatedMsg: '版本已更新，即将重整页面',
      TokenExpired: 'Token 过期',
      TokenExpiredMsg: '您的 Token 已过期，请重新登入',
      RepeatLogin: '重复登入',
      RepeatLoginMsg: '您的帐号已重复登入，请重新登入',
      PromotionCheckMsg: '绑定活动中，是否继续存款?',
      RelievePromotion: '解除活动',
      JoinDepositPromotionMsg: '是否参加存款赠活动?',
      AgentCodeHint: '欢迎来到 Ruai99, 请输入推荐码或 {code}@',
      AgentCodeHintG88: '欢迎来到 Gold88, 请输入推荐码或 {code}@',
      AccountFormat: '6~12字符包含英文+数字',
      PwdFormat: '6~12字符包含英文+数字',
      ConfirmPwd: '请再次输入密码',
      ConfirmToChange: '是否確定兌換?',
      OTPMsg: 'OTP验证码送出成功',
      PromotionVIPLevel: 'VIP{level}才可参加此活动'
    },
    EnterAnyPress: '输入任意键',
    PleaseBindAccount: '请先绑定帐号',
    AutoDeposit: {
      1: '存款银行帐号必须与注册时的银行帐号相同',
      2: '只能使用ATM，网路银行，手机银行进行存款动作',
      3: '请存款到以上帐户（按”Copy”便可复制以上帐户号码）',
      4: '完成存款动作后，需等1-3分钟，系统将会自动帮您上分',
      5: '请避免晚间23:00-3:00 存款至KTB银行，因此时段是银行的自动存款系统维护时间，导致无法进行存款作业',
      6: '玩家若需要进行存款，请联络我们的客服人员',
      note1: '如不是用与注册时相同帐户进行存款将不会进行上分与退款',
      note2: ' 如您是第一次使用自动上分，请至会员中心完善资料'
    },
    AutoDepositContent: `1. 存款银行帐号必须与注册时的银行帐号相同

    2. 只能使用ATM，网路银行，手机银行进行存款动作

    3. 请存款到以上帐户（按”Copy”便可复制以上帐户号码）

    4. 完成存款动作后，需等1-3分钟，系统将会自动帮您上分

    5. 请避免晚间23:00-3:00 存款至KTB银行，因此时段是银行的自动存款系统维护时间，导致无法进行存款作业

    6. 玩家若需要进行存款，请联络我们的客服人员

    * 如不是用与注册时相同帐户进行存款将不会进行上分与退款 *

    * 如您是第一次使用自动上分，请至会员中心完善资料 *`,
    BindAccountMsg: '尚未绑定帐号，若登出将无法取回帐号，是否绑定帐号?',
    PleaseEnterInSec: '請在 {Sec} 秒內輸入',
    PromotionReleaseTime: '解除活动流程需时约3~5分钟完成',
    OTPPhoneNotSame: '验证码与手机号码不符，请重新取得验证码'
  },
  Member: {
    Info: {
      ReferrerAccount: '推荐人帐号'
    }
  },
  Setting: {
    Title: '设定'
  },
  Inbox: {
    tab: {
      System: '系统公告',
      Promotion: '营运活动',
      Rewards: '得奖通知'
    }
  },
  Cash: {
    Status: {
      0: '建立订单', // 建立订单
      1: '审核中', // 审核中
      2: '审核成功', // 审核成功
      3: '审核拒绝', // 审核拒绝
      4: '取消交易' // 取消交易
    }
  },
  SystemMail: {
    tab: {
      System: '系统公告',
      Promotion: '营运活动',
      Rewards: '得奖通知'
    }
  },
  ThirdPartPayment: {
    MinimumDeposit: '单次下限',
    MaximumDeposit: '单次上限',
    DailyLimit: '每日限制',
    HandingFee: '手续费',
    NoHotKey: '无法使用此存款方式参加活动',
    Methods: {
      BANK: 'Bank Transfer',
      BANK_QRCODE: 'Bank QRcode',
      MOMO: 'MOMO PAY',
      ZALO: 'ZALO PAY',
      TNG: "Touch'n Go",
      WAVE_MONEY_TRANSFER: 'WaveMoney',
      DUITNOW_QRCODE: 'DuitNow',
      TRUE_MONEY_WALLET: 'TrueMoney',
      GCASH: 'GCash',
      GRABPAY: 'GrabPAY',
      ManualDeposit: '存款'
    },
    Fields: {
      Channel: '渠道',
      Name: '名称',
      Phone: '电话',
      TransactionID: 'TransactionNo',
      BankName: '银行名称',
      BankAccount: '银行帐号',
      BankList: '请选择银行'
    }
  },
  DailyMission: {
    Mission: {
      Bet: '{Threshold} 押注量',
      Win: '{Threshold} 赢分',
      BetTimes: '{Threshold} 押注次数',
      DepositAmount: '{Threshold} 存款',
      Referrer: '{Threshold} 推荐人',
      AllGames: '在所有游戏',
      MultiGames: '在多种游戏',
      DailyLogin: '每日登入'
    }
  },

  GameType: {
    'Slot Game': '老虎机',
    'Table Game': 'Table',
    'Card Game': 'Card',
    'Arcade Game Fisher': '捕鱼机',
    'Arcade Game': '街机',
    'Live Game': 'Live'
  }
}
