import API from '../../api'

const state = {
  Mails: {
    isLoading: false,
    data: {}
  },
  HasNoRead: {
    isLoading: false,
    data: {}
  }
}

const getters = {
  Mails: state => state.Mails,
  HasNoRead: state => state.HasNoRead
}

const mutations = {
  SET_MAILS: (state, data) => {
    state.Mails = data
  },
  SET_HASNOREAD: (state, data) => {
    state.HasNoRead = data
  }
}

const actions = {
  getMails: async ({ commit, state }) => {
    return new Promise(async (resolve, reject) => {
      if (state.Mails.isLoading) {
        console.warn('System mail讀取中')
        return reject('error')
      }
      commit('SET_MAILS', { ...state.Mails, isLoading: true })
      const res = await API.Message.Get_SystemList().catch(() => {
        console.log(err)
        commit('SET_MAILS', { ...state.Mails, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_MAILS', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_MAILS', { ...state.Mails, isLoading: false })
        return reject('error')
      }
    })
  },
  getHasNoRead({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.HasNoRead.isLoading) {
        console.warn('DailyMission Reward讀取中')
        return reject('error')
      }
      commit('SET_HASNOREAD', { ...state.HasNoRead, isLoading: true })
      const res = await API.Message.Get_NoreadMail().catch(() => {
        console.log(err)
        commit('SET_HASNOREAD', { ...state.HasNoRead, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_HASNOREAD', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_HASNOREAD', { ...state.HasNoRead, isLoading: false })
        return reject('error')
      }
    })
  },
  getAllData({ dispatch }) {
    dispatch('getMails')
    dispatch('getHasNoRead')
  },
  resetLoading({ commit, state }) {
    commit('SET_MAILS', { ...state.Mails, isLoading: false })
    commit('SET_HASNOREAD', { ...state.HasNoRead, isLoading: false })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
