const getters = {
  Token: state => state.user.tn,
  Device: state => state.user.device,
  AgentCode: state => state.user.ac,
  DeviceID: state => state.user.DeviceID,
  LocationID: state => state.app.LocationID,
  Version: state => state.app.version,
  language: state => state.app.language,
  isGameView: state => state.app.isGameView,
  isLoading: state => state.app.isLoading,
  MemberInfo: state => state.user.MemberInfoData,
  CurrencyID: state => state.user.MemberInfoData.CurrencyID,
  CurrencyRate: state => state.user.MemberInfoData.CurrencyRate,
  CurrencyName: state => state.user.MemberInfoData.CurrencyName,
  FBInfo: state => state.user.FBInfo,
  TimerCount: state => state.timer.count,
  TimerLock: state => state.timer.lock,
  TimerTempData: state => state.timer.tempData,
  TimerFirstClick: state => state.timer.firstClick,
  favGames: state => state.app.favGames,
  isAlertShow: state => state.app.isAlertShow,
  isMaintainence: state => state.app.isMaintainence,
  MaintainContent: state => state.app.MaintainContent,
  Domains: state => state.app.Domains,
  ProviderList: state => state.app.ProviderList,
  SocketVersion: state => state.app.SocketVersion,
  HasDailyReward: state => state.user.HasDailyReward,
  GameFilter: state => state.app.GameFilter,
  isMemberInfoLoading: state => state.user.isMemberInfoLoading.isGameView,
  isOpenGameWallet: state => state.app.isOpenGameWallet,
  HasLottery: state => state.user.HasLottery,
  HasDiamondShop: state => state.user.HasDiamondShop,
  DiamondAmount: state => state.user.DiamondAmount,
  LocationCode: state => state.app.LocationCode,
  CanPromotionFinish: state => state.user.CanPromotionFinish,
  IsAvoidFlow: state => state.user.MemberInfoData.IsAvoidFlow,
}

export default getters
