import API from '../../api'

const state = {
  AgentBank: {
    isLoading: false,
    data: []
  },
  AgentSupportBank: {
    isLoading: false,
    data: []
  },
  MemberBank: {
    isLoading: false,
    data: {}
  },
  CheckPromotion: {
    isLoading: false,
    data: {}
  },
  DepositPromotion: {
    isLoading: false,
    data: []
  },
  PromotionGroup: {
    isLoading: false,
    data: []
  },
  PaymentCheck: {
    isLoading: false,
    data: []
  },
  ColumnData: {},
  PassbookRecord: {
    isLoading: false,
    data: []
  },
  CashInRecord: {
    isLoading: false,
    data: []
  },
  CashOutRecord: {
    isLoading: false,
    data: []
  }
}

const getters = {
  AgentBank: state => state.AgentBank,
  AgentSupportBank: state => state.AgentSupportBank,
  MemberBank: state => state.MemberBank,
  CheckPromotion: state => state.CheckPromotion,
  DepositPromotion: state => state.DepositPromotion,
  PromotionGroup: state => state.PromotionGroup,
  PaymentCheck: state => state.PaymentCheck,
  ColumnData: state => state.ColumnData,
  PassbookRecord: state => state.PassbookRecord,
  CashInRecord: state => state.CashInRecord,
  CashOutRecord: state => state.CashOutRecord
}

const mutations = {
  SET_AGENTBANK: (state, data) => {
    state.AgentBank = data
  },
  SET_AGENTSUPPORTBANK: (state, data) => {
    state.AgentSupportBank = data
  },
  SET_MEMBERBANK: (state, data) => {
    state.MemberBank = data
  },
  SET_CHECKPROMOTION: (state, data) => {
    state.CheckPromotion = data
  },
  SET_DEPOSITPROMOTION: (state, data) => {
    state.DepositPromotion = data
  },
  SET_PROMOTIONGROUP: (state, data) => {
    state.PromotionGroup = data
  },
  SET_PAYMENTCHECK: (state, data) => {
    state.PaymentCheck = data
  },
  SET_COLUMNDATA: (state, data) => {
    state.ColumnData = data
  },
  SET_PASSBOOKRECORD: (state, data) => {
    state.PassbookRecord = data
  },
  SET_CASHINRECORD: (state, data) => {
    state.CashInRecord = data
  },
  SET_CASHOUTRECORD: (state, data) => {
    state.CashOutRecord = data
  }
}

const actions = {
  async getAgentBank({ commit, state }) {
    if (state.AgentBank.isLoading) {
      console.warn('AgentBank讀取中')
      return
    }
    commit('SET_AGENTBANK', { ...state.AgentBank, isLoading: true })
    const res = await API.Bank.getList({ Action: 'Agent' }).catch(err => {
      console.log(err)
      commit('SET_AGENTBANK', { ...state.AgentBank, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_AGENTBANK', { data: res.Data?.BankList ?? [], isLoading: false })
    } else {
      commit('SET_AGENTBANK', { ...state.AgentBank, isLoading: false })
    }
  },
  async getAgentSupportBank({ commit, state }) {
    if (state.AgentSupportBank.isLoading) {
      console.warn('AgentSupportBank讀取中')
      return
    }
    commit('SET_AGENTSUPPORTBANK', { ...state.AgentSupportBank, isLoading: true })
    const res = await API.Bank.getList({ Action: 'AgentSupport' }).catch(err => {
      console.log(err)
      commit('SET_AGENTSUPPORTBANK', { ...state.AgentSupportBank, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_AGENTSUPPORTBANK', { data: res.Data?.BankList ?? [], isLoading: false })
    } else {
      commit('SET_AGENTSUPPORTBANK', { ...state.AgentSupportBank, isLoading: false })
    }
  },
  async getMemberBank({ commit, state }) {
    if (state.MemberBank.isLoading) {
      console.warn('MemberBank讀取中')
      return
    }
    commit('SET_MEMBERBANK', { ...state.MemberBank, isLoading: true })
    const res = await API.Bank.getList({ Action: 'Member' }).catch(err => {
      console.log(err)
      commit('SET_MEMBERBANK', { ...state.MemberBank, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_MEMBERBANK', { data: res.Data, isLoading: false })
    } else {
      commit('SET_MEMBERBANK', { ...state.MemberBank, isLoading: false })
    }
  },
  async getCheckPromotion({ commit, state }) {
    if (state.CheckPromotion.isLoading) {
      console.warn('check promotion 讀取中')
      return
    }
    commit('SET_CHECKPROMOTION', { ...state.CheckPromotion, isLoading: true })
    const res = await API.Activity.GetHasPromotionCanFinish().catch(e => {
      commit('SET_CHECKPROMOTION', { ...state.CheckPromotion, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_CHECKPROMOTION', { data: res.Data, isLoading: false })
    } else {
      commit('SET_CHECKPROMOTION', { ...state.CheckPromotion, isLoading: false })
    }
  },
  async getDepositPromotion({ commit, state }) {
    if (state.DepositPromotion.isLoading) {
      console.warn('存款贈 讀取中')
      return
    }
    commit('SET_DEPOSITPROMOTION', { ...state.DepositPromotion, isLoading: true })
    const res = await API.Financial.GetActivityDeposit().catch(e => {
      commit('SET_DEPOSITPROMOTION', { ...state.DepositPromotion, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_DEPOSITPROMOTION', { data: res.Data.Mission, isLoading: false })
    } else {
      commit('SET_DEPOSITPROMOTION', { ...state.DepositPromotion, isLoading: false })
    }
  },
  async getPromotionGroup({ commit, state }) {
    if (state.PromotionGroup.isLoading) {
      console.warn('存款贈群組 讀取中')
      return
    }
    commit('SET_PROMOTIONGROUP', { ...state.PromotionGroup, isLoading: true })
    const res = await API.Activity.GetDepositGroup().catch(e => {
      commit('SET_PROMOTIONGROUP', { ...state.PromotionGroup, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_PROMOTIONGROUP', { data: res.Data, isLoading: false })
    } else {
      commit('SET_PROMOTIONGROUP', { ...state.PromotionGroup, isLoading: false })
    }
  },
  async getPaymentCheck({ commit, state, dispatch }) {
    if (state.PaymentCheck.isLoading) {
      console.warn('payment check 讀取中')
      return
    }
    commit('SET_PAYMENTCHECK', { ...state.PaymentCheck, isLoading: true })
    const res = await API.Financial.PaymentCheck().catch(e => {
      commit('SET_PAYMENTCHECK', { ...state.PaymentCheck, isLoading: false })
    })
    if (res?.ErrorCode === 0) {
      commit('SET_PAYMENTCHECK', { data: res.Data, isLoading: false })
      const depositTab = res.Data?.find(e => e.Keyword === 'Deposit')
      if (depositTab) {
        const tppTab = depositTab.Info.filter(tab => tab.Keyword !== 'AutoDeposit' && tab.Keyword !== 'ManualDeposit')
        tppTab.forEach(tab => {
          tab.Info.forEach(item => {
            dispatch('getColumnData', { TabID: tab.TabID, ID: item.ID })
          })
        })
      }
    } else {
      commit('SET_PAYMENTCHECK', { ...state.PaymentCheck, isLoading: false })
    }
  },
  async getColumnData({ commit, state }, params) {
    const res = await API.Financial.GetTppColumn(params).catch(e => {
      console.log(e)
    })
    if (res?.ErrorCode === 0) {
      commit('SET_COLUMNDATA', { ...state.ColumnData, [`${params.TabID}_${params.ID}`]: res.Data })
    }
  },
  async getPassbookRecord({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.PassbookRecord.isLoading) {
        console.warn('Passbook 讀取中')
        return reject(new Error('Passbook error'))
      }
      commit('SET_PASSBOOKRECORD', { ...state.PassbookRecord, isLoading: true })
      const res = await API.Bank.GetAccounting().catch(e => {
        commit('SET_PASSBOOKRECORD', { ...state.PassbookRecord, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_PASSBOOKRECORD', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_PASSBOOKRECORD', { ...state.PassbookRecord, isLoading: false })
        return reject(new Error('Passbook error'))
      }
    })
  },
  async getCashInRecord({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.CashInRecord.isLoading) {
        console.warn('CashIn 讀取中')
        return reject(new Error('CashIn error'))
      }
      commit('SET_CASHINRECORD', { ...state.CashInRecord, isLoading: true })
      const res = await API.Bank.GetCashIn().catch(e => {
        commit('SET_CASHINRECORD', { ...state.CashInRecord, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_CASHINRECORD', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_CASHINRECORD', { ...state.CashInRecord, isLoading: false })
        return reject(new Error('CashInOut error'))
      }
    })
  },
  async getCashOutRecord({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      if (state.CashOutRecord.isLoading) {
        console.warn('CashOut 讀取中')
        return reject(new Error('CashOut error'))
      }
      commit('SET_CASHOUTRECORD', { ...state.CashOutRecord, isLoading: true })
      const res = await API.Bank.GetCashOut().catch(e => {
        commit('SET_CASHOUTRECORD', { ...state.CashOutRecord, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_CASHOUTRECORD', { data: res.Data, isLoading: false })
        return resolve(res)
      } else {
        commit('SET_CASHOUTRECORD', { ...state.CashOutRecord, isLoading: false })
        return reject(new Error('CashOut error'))
      }
    })
  },
  async getAllData({ dispatch }) {
    dispatch('getAgentBank')
    dispatch('getAgentSupportBank')
    dispatch('getMemberBank')
    dispatch('getCheckPromotion')
    dispatch('getPromotionGroup')
    dispatch('getPaymentCheck')
    dispatch('getPassbookRecord')
    dispatch('getCashInRecord')
    dispatch('getCashOutRecord')
  },
  async getAllDataWithoutCheck({ dispatch }) {
    dispatch('getAgentBank')
    dispatch('getAgentSupportBank')
    dispatch('getMemberBank')
    dispatch('getPromotionGroup')
    dispatch('getPaymentCheck')
    dispatch('getPassbookRecord')
    dispatch('getCashInRecord')
    dispatch('getCashOutRecord')
  },
  async resetLoading({ commit, state }) {
    commit('SET_AGENTBANK', { ...state.AgentBank, isLoading: false })
    commit('SET_AGENTSUPPORTBANK', { ...state.AgentSupportBank, isLoading: false })
    commit('SET_MEMBERBANK', { ...state.MemberBank, isLoading: false })
    commit('SET_CHECKPROMOTION', { ...state.CheckPromotion, isLoading: false })
    commit('SET_PROMOTIONGROUP', { ...state.PromotionGroup, isLoading: false })
    commit('SET_PAYMENTCHECK', { ...state.PaymentCheck, isLoading: false })
    commit('SET_PASSBOOKRECORD', { ...state.PassbookRecord, isLoading: false })
    commit('SET_CASHINRECORD', { ...state.CashInRecord, isLoading: false })
    commit('SET_CASHOUTRECORD', { ...state.CashOutRecord, isLoading: false })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
