import API from '../../api'

const state = {
  Promotions: {
    isLoading: false,
    data: {}
  },
  LevelInfo: {
    isLoading: false,
    data: {}
  },
  JoinedPromoCode: null
}

const getters = {
  Promotions: state => state.Promotions,
  LevelInfo: state => state.LevelInfo.data,
  JoinedPromoCode: state => state.JoinedPromoCode
}

const mutations = {
  SET_PROMOTIONS: (state, data) => {
    state.Promotions = data
  },
  SET_LEVELINFO: (state, data) => {
    state.LevelInfo = data
  },
  SET_JOINEDPROMOCODE: (state, data) => {
    state.JoinedPromoCode = data
  }
}

const actions = {
  getPromotions: async ({ commit, state }) => {
    return new Promise(async (resolve, reject) => {
      if (state.Promotions.isLoading) {
        console.warn('Promotion讀取中')
        reject(new Error('error'))
      }
      commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: true })
      const res = await API.Activity.Get_PromotionList().catch((err) => {
        console.log(err)
        commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false })
      })
      if (res?.ErrorCode === 0) {
        commit('SET_PROMOTIONS', { data: res.Data, isLoading: false })
        resolve(res)
      } else {
        commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false })
        reject(new Error('error'))
      }
    })
  },
  async getLevelInfo({ commit, state }) {
    if (state.LevelInfo.isLoading) {
      console.warn('Level Info 讀取中')
      return
    }
    commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: true })
    const res = await API.Activity.GetUserLevelIntro().catch(e => {
      console.log(e)
      commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false })
    })
    commit('SET_LEVELINFO', false)
    if (res?.ErrorCode === 0) {
      commit('SET_LEVELINFO', { data: res.Data, isLoading: false })
    } else {
      commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false })
    }
  },
  getAllData({ dispatch }) {
    dispatch('getPromotions')
    dispatch('getLevelInfo')
  },
  resetLoading({ commit, state }) {
    commit('SET_PROMOTIONS', { ...state.Promotions, isLoading: false })
    commit('SET_LEVELINFO', { ...state.LevelInfo, isLoading: false })
  },
  setJoinedPromoCode({ commit }, data) {
    commit('SET_JOINEDPROMOCODE', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
