export default {
  Shared: {
    Login: 'လော့အင်',
    Add: 'အသစ်တိုးမည်',
    Edit: ' ပြင်ဆင်မည်',
    Query: 'ရှာဖွေမည်',
    Submit: 'တင်မည်',
    Register: 'မှတ်ပုံတင်သည်',
    ForgotPassword: 'စကားဝှက်မေ့သည်',
    QuickLogin: 'အစမ်းကစားရန်',
    PleaseChoose: 'ကျေးဇူးပြုပြီးရွေးချယ်ပါ',
    PleaseEnter: 'ကျေးဇူးပြု၍ ထည့်သွင်းပါ',
    Success: 'အောင်မြင်သည်',
    Fail: 'မအောင်မြင်ပါ',
    Error: 'မှားနေသည်',
    Format: 'ပုံစံ',
    DetailInfo: 'မန်ဘာအချက်အလက်',
    ChangePassword: 'စကားဝှက်ပြောင်းရန်',
    BindAccount: 'အကောင့်ချိတ်မည်',
    FacebookBind: 'Facebook ချိတ်မည်',
    BindReferrer: 'မိတ်ဆက်အကောင့်ချိတ်ဆက်ခြင်း',
    Account: 'အကောင့်',
    Password: 'လျှို့ဝှက်နံပါတ်',
    PhoneNumber: 'ဖုန်းနံပါတ်',
    VerifyCode: 'အတည်ပြုကုဒ်',
    ReSend: 'အသစ်ပြန်ပို့ခြင်း',
    NewPassword: 'လျှို့ဝှက်နံပါတ်အသစ်',
    ConfirmPassword: 'လျှို့ဝှက်နံပါတ်အတည်ပြုခြင်း',
    Deposit: 'ငွေသွင်းခြင်း',
    Withdraw: 'ငွေထုတ်ခြင်း',
    Tpp: '3rd-Party ငွေပေးချေမှု',
    AutoDeposit: 'အော်တိုငွေသွင်းခြင်း',
    Records: 'မှတ်တမ်းများ',
    AgentBank: 'အေးဂျင့်ဘဏ်',
    MemberBank: 'ဘဏ်အကောင့်',
    Amount: 'ပမာဏ',
    Receipt: 'ပြေစာ',
    Wallet: 'ပိုက်ဆံအိတ်',
    Owner: 'ပိုင်ရှင်',
    All: 'အားလုံး',
    Provider: 'ပံ့ပိုးပေးသူ',
    GameType: 'ဂိမ်းအမျိုးအစား',
    Name: 'နာမည်',
    Birthday: 'မွေးနေ့',
    IDCardNumber: 'မှတ်ပုံတင်',
    Verify: 'အတည်ပြုသည်',
    OTPVerifyCode: 'OTP ကုဒ်အတည်ပြုသည်',
    Bank: 'ဘဏ်',
    BankAccount: 'ဘဏ်အကောင့်',
    BankPic: 'ဘဏ်ပုံ',
    Email: 'အီးမေးလ်',
    OriginalPassword: 'မူရင်း လျှို့ဝှက်နံပါတ်',
    ReferrerAccount: 'မိတ်ဆက်သူအကောင့်',
    RemainingFlow: 'လက်ကျန်ကြိမ်ရေ',
    Date: 'နေ့စွဲ',
    Flow: 'ကြိမ်ရေ',
    Detail: 'အသေးစိတ်',
    Join: 'ပါဝင်မည်',
    Clear: 'ရှင်းလင်းမည်',
    Promotion: 'ပရိုမိုးရှင်း',
    DailyMission: 'နေ့စဉ်လုပ်ဆောင်ချက်',
    Collect: 'စုဆောင်းသည်',
    Finish: 'ပြီးဆုံးရန်',
    Invite: 'မိတ်ဆက်မည်',
    Copy: 'ကူးယူသည်',
    NoPromotion: 'ပရိုမိုးရှင်း မရှိပါ',
    NoMission: 'လုပ်ဆောင်ချက်မရှိပါ',
    NoAuthority: 'လုပ်ဆောင်ခွင့်မရှိပါ',
    GoToBindMember: 'သင်၏အချက်အလက်ကို ဖြည့်ပါ',
    Inbox: 'ဝင်စာ',
    Defined: 'အတည်ပြုသည်',
    Cancel: 'ပယ်ဖျက်သည်',
    Confirm: 'အတည်ပြုသည်',
    OK: 'OK',
    Advertisement: 'ကြေငြာ',
    BettingRecord: 'အနိုင်အရှုံးမှတ်တမ်း',
    Renew: 'နောက်တစ်ကြိမ်ရှာရန်',
    Required: 'လိုအပ်သည်',
    RotateDeviceContent: 'အကောင်းဆုံးကစားနိုင်ရန်ဖုန်းကိုလှည့်ပြီးကစားပါ',
    Memo: 'မှတ်စုတို',
    SystemMail: 'System ဝင်စာ',
    Contact: 'ဆက်သွယ်ရန်',
    Bind: 'ချိတ်ဆက်ပါ',
    Music: 'ဂီတ',
    Language: 'ဘာသာစကား',
    Version: 'ဗားရှင်း',
    Logout: 'ထွက်မည်',
    Maintenance: 'ပြုပြင်ထိန်းသိမ်းမှုစနစ်',
    Profile: 'ကိုယ်ရေးအချက်လက်',
    Level: 'အဆင့်',
    PreAmount: 'ပရိုမိုးရှင်းပယ်ဖျက်ပြီးပိုငွေများနှုတ်ယူမှု',
    LanguageChangeMsg: 'ဘာသာစကားသည် ပြောင်းလဲပြီး စာမျက်နှာကို ပြန်လည်စတင်မည်ဖြစ်သည်',
    NoData: 'ဒေတာမရှိပါ',
    AccumulatedWager: 'စုဆောင်းထားသော ကြိမ်ရေ',
    PromotionThreshold: 'ပရိုမိုးရှင်းလိုအပ်ချက်',
    Remaining1xWager: 'ကျန်နေသောကြိမ်ရေ1ဆ',
    Install: 'အင်စတော',
    PromptMsg: 'ကျွန်ုပ်တို့၏ အခမဲ့Appကို ရယူလိုက်ပါ။ ၎င်းသည် သင့်ဖုန်းတွင် နေရာအများမကုန်ပါ',
    SafariHint0: 'Safari မှဖွင့်ပါ',
    SafariHint1: 'Tap ကိုနှိပ်ပါ',
    SafariHint2: 'ပင်မစာမျက်နှာသို့ထည့်ရန် ရွေးပါ',
    Locked: 'သော့ခတ်ထားသည်',
    TimeRemaining: 'ကျန်ရှိနေသောအချိန်',
    LeaderBoard: 'ကျား နဂါးဆုစာရင်း',
    Daily: 'နေ့စဉ်',
    Weekly: 'အပတ်စဉ်',
    Monthly: 'လစဉ်',
    EndDate: 'ကုန်ဆုံးရက်စွဲ',
    Create: 'တည်‌ဆောက်သည်',
    GoBack: 'နောက်သို့ပြန်သွားမည်',
    SpecialOffer: 'အထူးပရိုမိုးရှင်း',
    PleaseEnterInSec: 'ကျေးဇူးပြု၍ {Sec} စက္ကန့်အတွင်းဖြည့်ပေးပါ',
    MemberShipLevel: 'မန်ဘာအဆင့်ဖော်ပြချက်',
    Forever: 'အမြဲတမ်း',
    Year: 'နှစ်',
    DailyMissionReward: 'နေ့စဉ်လုပ်ဆောင်မှုဆု',
    VIP2Info: 'မန်ဘာအချက်အလက်အတည်ပြုပြီး',
    VIP3Info: 'ပထမဦးဆုံးသွင်းငွေ',
    VIP4Info: '2 လအတွင်းကြိမ်ရေ {Amount}',
    VIP5Info: '2 လအတွင်းကြိမ်ရေ {Amount}',
    VIP6Info: '2 လအတွင်းကြိမ်ရေ {Amount}',
    VIPDepositInfo: 'သွင်းငွေ {Amount} နှစ်လအတွင်း',
    Activated: 'ရပါပြီ',
    PlayGame: 'ဂိမ်းကစားမည်',
    Reward: 'ဆု',
    Passbook: 'ငွေသွင်းစာရင်းစာအုပ်',
    DepositRecord: 'ငွေသွင်းမှတ်တမ်း',
    WithdrawalRecord: 'ငွေထုတ်မှတ်တမ်း',
    AgentCode: 'မိတ်ဆက်ကုဒ်',
    GameAccount: 'ဂိမ်းအကောင့်',
    JoinPromotion: 'ပရိုမိုးရှင်းပါဝင်မည်',
    Spin: 'လှည့်မည်',
    Congratulation: 'ဂုဏ်ယူပါတယ်',
    TicketNotEnough: 'လက်မှတ်မလုံလောက်ပါ။',
    RewardMsg: 'သင်သည် {num} ကို ရရှိပြီ။ ',
    BounsWheel: 'ဘောနပ်စ်ဘီး',
    Yes: 'ပါဝင်မည်။',
    No: 'မပါဝင်ပါ။',
    DiamondShop: 'စိန်ဆိုင်',
    GameRecord: 'ဂိမ်းမှတ်တမ်',
    Setting: 'ဆက်တင်',
    DiamondInsufficient: 'စိန်မလုံလောက်ပါ။',
    FullScreenMode: 'မျက်နှာပြင်အပြည့်မုဒ်သို ဝင်ရောက်ပါမည်',
    RewardPromotion: 'ပရိုမိုးရှင်း',
    RebatePromotion: 'ကော်မရှင်',
    ReferrerPromotion: 'မိတ်ဆက်ကော်မရှင်း',
    DepositPromotion: 'ငွေသွင်းပရိုမိုးရှင်း',
    LotteryPromotion: 'မဲနှိုက်ပရိုမိုးရှင်း',
    '1xTurnover': 'ကြိမ်ရေတစ်ဆ',
    AccumulatedWagerInfo: 'လက်ရှိပရိုမိုးရှင်းကြိမ်ရေဖြစ်ပါသည် သတ်မှတ်ထားသောကြိမ်ရေပြည့်လျှင် ပရိုမိုးရှင်းကိုပယ်ဖျက်နိုင်ပါသည် \n ပရိုမိုးရှင်းသတ်မှတ်ကြိမ်ရေ   {max}',
    NoLimit: 'No Limit',
    TotalBet: 'စုစုပေါင်းကြိမ်ရေ',
    TotalDeposit: 'စုစုပေါင်းသွင်းငွေ',
    VIPPrivileges: 'VIP အကျိုးခံစားခွင့်',
    Requirement: 'အခြေအနေ',
    ReferralInfo: 'မိတ်ဆက်သူအချက်လက်',
    UserName: 'ဂိမ်းအကောင့်',
    CompanyProfit: 'ကုမ္ပဏီနိုင်ငွေ',
    Bonus: 'ဘောနပ်စ်',
    SystemRecycle: 'ပြန်နှုတ်ငွေ',
    CurrentMonth: 'ယခုလ',
    PreviousMonth: 'အရင်လ',
    FeedbackAmount: 'မိတ်ဆက်ကော်မရှင်',
    MinBetAmount: 'အနည်းဆုံးလောင်းကြေး: {Amount}',
    PromoCode: 'ပရိုမိုးရှင်းကုဒ်နံပါတ်',
    PrivatePolicy: 'ကိုယ်ရေးအချက်အလက်မူဝါဒ',
    VerifyInfo: 'အချက်အလက်အတည်ပြုပါ',
    Subscribe: 'အသိပေးချက်',
    A2HS: 'Add to Home Screen',
    Introduction: 'လုပ်ဆောင်နည်း',
    Next: 'ရှေ့သို့။',
    WagerLimit: 'ကြိမ်ရေကန့်သတ်ချက်',
    WagerLimitDesc: 'သွင်းငွေရဲ့ကြိမ်ရေ1ဆ နှင့် နေ့စဥ်ဆုငွေကြိမ်ရေ',
    NetwinWagerDesc: 'သတ်မှတ်ထားတဲ့နိုင်မှတ်သိုရောက်ရင် ပရိုမိုးရှင်းအားပယ်ဖျက်နိုင်သည်\n ပရိုမိုးရှင်းလိုအပ်ချက်: {max}',
    Quit: 'ထွက်မည်',
    AskJoinAutoDepositPromotion: 'အော်တို အမှတ်ဖြည့်ဘောနပ် ချိတ်မှာလား',
    AskCancelJoinedPromotion: 'အော်တို အမှတ်ဖြည့်ဘောနပ် ဘယ်ဖြတ်မှာလား',
    Netwin: 'နိုင်မှတ်',
    ReferralCode: 'မိတ်ဆက်ကုဒ်',
    ReferralQRCode: 'မိတ်ဆက် QRCode',
    EnterReferralCode: 'မိတ်ဆက်ကုဒ်ကို ထည့်ပါ။',
    ReferralCodeDoesNotExist: 'မိတ်ဆက်ကုဒ်မရှိပါ။',
    ReferralByUser: '{username} မှ မိတ်ဆက်ထားသည်',
    NoPromotionItem: 'ကျွန်ုပ်တို့၏စကားများအတွက် အကွာအဝေးမရှိပါ'
  },
  CashOut: {
    Fields: {
      OrderNumber: ' Order  နံပါတ်',
      AuditAmount: 'ပမာဏ',
      Status: 'အခြေအနေ',
      CreateTime: 'တည်‌ဆောက်သည့်အချိန်'
    }
  },
  CashIn: {
    Fields: {
      OrderNumber: ' Order  နံပါတ်',
      AuditAmount: 'ပမာဏ',
      Status: 'အခြေအနေ',
      CreateTime: 'တည်‌ဆောက်သည့်အချိန်'
    }
  },
  Accounting: {
    Fields: {
      Source: 'အရင်းအမြစ်',
      Type: 'အမျိုးအစား',
      Quantity: 'ပမာဏ',
      CreateTime: 'တည်‌ဆောက်သည့်အချိန်။'
    },
    Type: {
      1: 'အမှတ်', // 金幣
      5: 'စိန်'
    },
    Passbook: {
      NoviceActivityReward: 'မန်ဘာအသဟ်လုပ်ဆောင်ချက်ဆု',
      ReferrerActivityReward: 'မိတ်ဆက်လုပ်ဆောင်ချက်ဆု',
      PromotionActivityReward: 'ပရိုမိုးရှင်းလုပ်ဆောင်ချက်ဆု',
      FeedbackActivityReward: 'ကော်မရှင်လုပ်ဆောင်ချက်ဆု',
      SystemReward: 'စနစ်ဆု',
      OtherReward: 'အခြားဆုကြေးငွေ',
      RecycleCategory: 'ပရိုမိုးရှင်းအမောက်ရုပ်သိမ်း',
      DepositActivityReward: 'အထူးငွေသွင်းပရိုမိုးရှင်း',
      DailyMissionReward: 'နေ့စဉ်လုပ်ဆောင်ချက်ဆု',
      Lottery: 'မဲနှိုက်ဆု',
      DiamondMall: 'Shop Transaction',
      AbnormalOrderRecycle: 'ဖွက်သိမ်းငွေ ပြန်နှုတ်ခြင်း'
    }
  },
  BettingRecord: {
    Fields: {
      ProviderName: 'ပံ့ပိုးပေးသူ',
      GameType: 'ဂိမ်းအမျိုးအစား',
      Turnover: 'ကြိမ်ရေ',
      Netwin: 'အနိုင်အရှုံး',
      BetTime: 'ဂိမ်းကစားသည့်အချိန်'
    }
  },
  Message: {
    Msg: {
      HowToDeposit: 'ငွေသွင်းနည်း',
      PleaseUploadImage: 'ပုံတင်ပါ',
      AmountLessThen: 'ပမာဏသည် {Amount} ထက် မနည်းရ',
      AmountGreaterThen: 'ပမာဏသည် {Amount} ထက် မပိုရ',
      AreYouPromotionFinished: 'ပရိုမိုးရှင်းပြီးဆုံးရန် သေချာလား?',
      PleaseEnterCellPhone: 'ဖုန်းနံပါတ်ထည့်ပါ',
      DoYouWantToWithdraw: 'လူကြီးမင်း ငွေအမောက် {Amount} ကျပ်ထုတ်ယူပါမည် ?',
      DoYouWantToDeposit: 'လူကြီးမင်း ငွေအမောက် {Amount} ကျပ်ထည့်သွင်းပါမည် ?',
      DoYouChangeLanguage: 'ဘာသာစကားပြောင်းချင်သလား',
      VersionUpdated: 'ဗားရှင်းအပ်ဒိတ်',
      VersionUpdatedMsg: 'ဗားရှင်းမွမ်းမံပြီးပါပြီ၊ စာမျက်နှာကို ယခု ပြန်လည်စတင်ပါမည်',
      TokenExpired: 'တိုကင်သက်တမ်းကုန်ပြီ',
      TokenExpiredMsg: 'သင်၏ Token သက်တမ်းကုန်နေပြီ၊ ကျေးဇူးပြု၍ ပြန်လည်ဝင်ရောက်ပါ။',
      RepeatLogin: 'လော့အင်နေရာထပ်နေသည်',
      RepeatLoginMsg: 'သင်၏အကောင့်လော့အင်နေရာထပ်နေသည် ကျေးဇူးပြုပြီး ပြန်ဝင်ပါ',
      PromotionCheckMsg: 'ပရိုမိုးရှင်းပါဝင်နေသည် ငွေဆက်သွင်းမလား ?',
      RelievePromotion: 'ပရိုမိုရှင်းပယ်ဖျတ်မည်',
      JoinDepositPromotionMsg: 'ငွေသွင်းပရိုမိုးရှင်းပါဝင်မလား?',
      ConfirmToChange: 'လဲလှယ်ရန် အတည်ပြုလား?',
      AgentCodeHint: 'Welcome to Ruai99, Please enter referral code or {code}@',
      AgentCodeHintG88: 'ကျေးဇူးပြု၍ ဤကုဒ်ကို ထည့်ပါ။ ：{code}@',
      AccountFormat: 'အက္ခရာ+နံပတ် အပါအဝင် 6-12 လုံး',
      PwdFormat: 'အက္ခရာ+နံပတ် အပါအဝင် 6-12 လုံး',
      ConfirmPwd: 'Please enter password again',
      OTPMsg: 'အတည်ပြုကုတ်အောင်မြင်စွာပိုလိုက်ပါပြီ',
      PromotionVIPLevel: 'VIP{level} မှဤပရိုမိုးရှင်းပါဝင်နိုင်ပါသည်'
    },
    EnterAnyPress: 'Any Key ကိုနှိပ်ပါ။',
    PleaseBindAccount: 'ကျေးဇူးပြု၍ အကောင့်ချိတ်ပါ',
    AutoDeposit: {
      1: 'ငွေသွင်းဘဏ်အကောင့်သည်အကောင့်ဖွင့်ချိန်ချိတ်ထားသည့်ဘဏ်အကောင့်နဲတူရမည်',
      2: 'ငွေသွင်းရန်အတွက် ATM အင်တာနက်ဘဏ်လုပ်ငန်း သို့မဟုတ် မိုဘိုင်းလ်ဘဏ်စနစ်ကိုသာ အသုံးပြုပါ။',
      3: 'အပေါ်ဘက်ရှိ ဘဏ်အကောင့်သို့ ငွေလွှဲပါ (ဘဏ်အကောင့်နံပါတ်ကို ကူးယူရန် "Copy" ကိုနှိပ်ပါ)',
      4: 'ငွေသွင်းခြင်းပြီးပါက 1-3 မိနစ်စောင့်ဆိုင်းပြီးနောက် system သည် အမှတ်များကို အော်တိုထည့်ပါမည်။',
      5: '“ဘဏ်ဝဘ်ဆိုဒ်တွေကို ပြုပြင်ထိန်းသိမ်းရမယ့်အချိန်ရှိလို့  ည၂၃ နာရီမှာ ငွေသွင်းတာကို ရှောင်ပါ',
      6: 'ငွေသွင်းလိုပါကကျွန်ုပ်တို့၏ customer service သို့ ဆက်သွယ်ပေးပါ',
      note1: 'အကောင့်ဖွင့်ချိန်ချိတ်ထားသည့်ဘဏ်အကောင့်ကို အသုံးမပြုပါက systemသည် အမှတ်များကို ထည့်သွင်းခြင်း သို့မဟုတ် အ‌ကောင့်သို့ပြန်လွှဲပေးမည်မဟုတ်ပါ။',
      note2: 'Auto deposit ကို ပထမဆုံးအကြိမ် အသုံးပြုလျှင် မန်ဘာအချက်လက်များကို အရင်ဆုံးဖြည့်ပေးပါ'
    },
    AutoDepositContent: `1. ငွေသွင်းဘဏ်အကောင့်သည်အကောင့်ဖွင့်ချိန်ချိတ်ထားသည့်ဘဏ်အကောင့်နဲတူရမည်

    2. ငွေသွင်းရန်အတွက် ATM အင်တာနက်ဘဏ်လုပ်ငန်း သို့မဟုတ် မိုဘိုင်းလ်ဘဏ်စနစ်ကိုသာ အသုံးပြုပါ။

    3. အပေါ်ဘက်ရှိ ဘဏ်အကောင့်သို့ ငွေလွှဲပါ (ဘဏ်အကောင့်နံပါတ်ကို ကူးယူရန် "Copy" ကိုနှိပ်ပါ)

    4. ငွေသွင်းခြင်းပြီးပါက 1-3 မိနစ်စောင့်ဆိုင်းပြီးနောက် system သည် အမှတ်များကို အော်တိုထည့်ပါမည်။

    5. “ဘဏ်ဝဘ်ဆိုဒ်တွေကို ပြုပြင်ထိန်းသိမ်းရမယ့်အချိန်ရှိလို့  ည၂၃ နာရီမှာ ငွေသွင်းတာကို ရှောင်ပါ

    6. ငွေသွင်းလိုပါကကျွန်ုပ်တို့၏ customer service သို့ ဆက်သွယ်ပေးပါ

    * အကောင့်ဖွင့်ချိန်ချိတ်ထားသည့်ဘဏ်အကောင့်ကို အသုံးမပြုပါက systemသည် အမှတ်များကို ထည့်သွင်းခြင်း သို့မဟုတ် အ‌ကောင့်သို့ပြန်လွှဲပေးမည်မဟုတ်ပါ။ *

    * Auto deposit ကို ပထမဆုံးအကြိမ် အသုံးပြုလျှင် မန်ဘာအချက်လက်များကို အရင်ဆုံးဖြည့်ပေးပါ *`,
    BindAccountMsg: 'အကောင့်ကို ချည်နှောင်ထားခြင်း မရှိသေးပါက အကောင့်ထွက်ပါက ပြန်လည်ရယူ၍ မရနိုင်ပါ။ အကောင့်ကို ချည်နှောင်လိုပါသလား။',
    PleaseEnterInSec: 'ကျေးဇူးပြု၍ {Sec} စက္ကန့်အတွင်း ထည့်ပါ။',
    PromotionReleaseTime: 'ပရိုမိုးရှင်းပယ်ဖျတ်ရန် 3~5မိနစ် ကြာပါမည်',
    OTPPhoneNotSame: 'အတည်ပြုကုဒ်နှင့်ဖုန်းနံပါတ်မတူညီပါ အတည်ပြုကုဒ်အသစ်ထပ်တောင်းပါ'
  },
  Member: {
    Info: {
      ReferrerAccount: 'မိတ်ဆက်အကောင့်'
    }
  },
  Setting: {
    Title: 'ဆက်တင်များ'
  },
  Inbox: {
    tab: {
      System: 'system အကြောင်းကြားစာ',
      Promotion: 'ပရိုမိုးရှင်းအကြောင်းကြားစာ',
      Rewards: 'ဆုငွေအကြောင်းကြားစာ'
    }
  },
  Cash: {
    Status: {
      0: ' အော်ဒါတင်ထားခြင်း', // 建立訂單
      1: ' စစ်ဆေးနေသည်', // 審核中
      2: ' စစ်ဆေးခြင်းအောင်မြင်သည်', // 審核成功
      3: ' စစ်ဆေးခြင်းငြင်းပယ်သည်', // 審核拒絕
      4: ' ငွေပေးငွေယူကို ပယ်ဖျက်လိုက်ပါပြီ။' // 取消交易
    }
  },
  SystemMail: {
    tab: {
      System: 'system အကြောင်းကြားစာ',
      Promotion: 'ပရိုမိုးရှင်းအကြောင်းကြားစာ',
      Rewards: 'ဆုငွေအကြောင်းကြားစာ'
    }
  },
  ThirdPartPayment: {
    MinimumDeposit: 'တစ်ကြိမ်ပေးချေမှု အနည်းဆုံးပမာဏ',
    MaximumDeposit: 'တစ်ကြိမ်ပေးချေမှု အများဆုံးပမာဏ',
    DailyLimit: 'နေ့စဥ်ကန့်သတ်ချက်',
    HandingFee: 'လုပ်ဆောင်ခ',
    NoHotKey: 'ဤငွေပေးချေမှုနည်းလမ်းဖြင့် ပရိုမိုးရှင်းတွင် မပါဝင်နိုင်ပါ',
    Methods: {
      BANK: 'Bank Transfer',
      BANK_QRCODE: 'Bank QRcode',
      MOMO: 'MOMO PAY',
      ZALO: 'ZALO PAY',
      TNG: "Touch'n Go",
      WAVE_MONEY_TRANSFER: 'WaveMoney',
      DUITNOW_QRCODE: 'DuitNow',
      TRUE_MONEY_WALLET: 'TrueMoney',
      GCASH: 'GCash',
      GRABPAY: 'GrabPAY',
      ManualDeposit: 'ငွေသွင်းခြင်း'
    },
    Fields: {
      Channel: 'ချန်နယ်',
      Name: 'နာမည်',
      Phone: 'ဖုန်း',
      TransactionID: 'လုပ်ငန်းစဉ် ID',
      BankName: 'ဘဏ်အမည်',
      BankAccount: 'ဘဏ်အကောင့်',
      BankList: 'ဘဏ်ကိုရွေးချယ်ပါ။'
    }
  },
  DailyMission: {
    Mission: {
      Bet: 'နေ့စဥ် Bet အမောက်',
      Win: 'နေ့စဥ်နိုင်မှတ်',
      BetTimes: 'နေ့စဥ် Bet ထားသည့်ကြိမ်ပေါင်း',
      DepositAmount: 'နေ့စဥ်သွင်းငွေ',
      Referrer: 'မန်ဘာသစ် {Threshold} ယောက်မိတ်ဆက်',
      AllGames: 'ဂိမ်းအားလုံးတွင်',
      MultiGames: 'ဂိမ်းပေါင်းများစွာတွင်',
      DailyLogin: 'နေ့စဉ်ဝင်ရောက်ခြင်း'
    }
  },
  GameType: {
    'Slot Game': 'စလော့',
    'Table Game': 'Table',
    'Card Game': 'Card',
    'Arcade Game Fisher': 'ငါးပစ်',
    'Arcade Game': 'Arcade',
    'Live Game': 'Live'
  }
}
