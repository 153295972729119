export default {
  // --------------------------- แชร์ -------------------- ---------------
  Shared: {
    Login: 'เข้าสู่ระบบ',
    Add: 'เพิ่ม',
    Edit: 'แก้ไข',
    Query: 'แบบสอบถาม',
    Submit: 'ส่ง',
    Register: 'ลงทะเบียน',
    ForgotPassword: 'ลืมรหัสผ่าน',
    QuickLogin: 'ล็อคอินรวดเร็ว',
    PleaseChoose: 'กรุณาเลือก',
    PleaseEnter: 'กรุณาใส่',
    Success: 'ความสำเร็จ',
    Fail: 'ล้มเหลว',
    Error: 'ข้อผิดพลาด',
    Format: 'รูปแบบ',
    DetailInfo: 'ข้อมูลสมาชิก',
    ChangePassword: 'เปลี่ยนรหัสผ่าน',
    BindAccount: 'ผูกบัญชี',
    FacebookBind: 'ผูก Facebook',
    BindReferrer: 'ผูกผู้แนะนำ',
    Account: 'ยูสเซอร์',
    Password: 'รหัสผ่าน',
    PhoneNumber: 'หมายเลขโทรศัพท์',
    VerifyCode: 'ยืนยันรหัส',
    ReSend: 'ส่งใหม่',
    NewPassword: 'รหัสผ่านใหม่',
    ConfirmPassword: 'ยืนยันรหัสผ่าน',
    Deposit: 'ฝาก',
    Withdraw: 'ถอน',
    Tpp: 'การชำระเงินสามฝ่าย',
    AutoDeposit: 'ฝากอัตโนมัติ',
    Records: 'ประวัติธุรกรรม',
    AgentBank: 'ธนาคารที่จะโอน',
    MemberBank: 'ธนาคารสมาชิก',
    Amount: 'จำนวนเงิน',
    Receipt: 'ใบเสร็จรับเงิน',
    Wallet: 'กระเป๋า',
    Owner: 'เจ้าของ',
    All: 'ทั้งหมด',
    Provider: 'ผู้ให้บริการ',
    GameType: 'ประเภทเกม',
    Name: 'ชื่อ',
    Birthday: 'วันเกิด',
    IDCardNumber: 'บัตรประจำตัวประชาชน',
    Verify: 'ยืนยัน',
    OTPVerifyCode: 'รหัสยืนยัน OTP',
    Bank: 'ธนาคาร',
    BankAccount: 'หมายเลขบัญชีธนาคาร',
    BankPic: 'รูปภาพธนาคาร',
    Email: 'อีเมล',
    OriginalPassword: 'รหัสผ่านเดิม',
    ReferrerAccount: 'บัญชีผู้อ้างอิง',
    RemainingFlow: 'น้ำที่ไหลเหลือ',
    Date: 'วันที่',
    Flow: 'ทำเทิร์น',
    Detail: 'รายละเอียด',
    Join: 'เข้าร่วม',
    Clear: 'ล้าง',
    Promotion: 'กิจกรรม',
    DailyMission: 'ภารกิจรายวัน',
    Collect: 'รวบรวม',
    Finish: 'เสร็จสิ้น',
    Invite: 'แนะนำเพื่อน',
    Copy: 'คัดลอก',
    NoPromotion: 'ไม่มีโปรโมชั่น',
    NoMission: 'ไม่มีภารกิจ',
    NoAuthority: 'ไม่มีสิทธิ์ใช้งาน',
    GoToBindMember: 'กรุณาผูกบัญชี',
    Inbox: 'ข้อความ',
    Defined: 'ระบุ',
    Cancel: 'ยกเลิก',
    Confirm: 'ยืนยัน',
    OK: 'โอเค',
    Advertisement: 'โฆษณา',
    BettingRecord: 'ประวัติเดิมพัน',
    Renew: 'เริ่มต้นใหม่',
    Required: 'ต้องการ',
    RotateDeviceContent: 'กรุณาพลิกอุปกรณ์เพื่อประสบการณ์เล่นที่ดีที่สุด',
    Memo: 'หมายเหตุ',
    SystemMail: 'อินบ็อกซ์',
    Contact: 'ติดต่อเรา',
    Bind: 'ผูกบัญชี',
    Music: 'ดนตรี',
    Language: 'ภาษา',
    Version: 'เวอร์ชั่น',
    Logout: 'ล็อกเอ้าท์',
    Maintenance: 'ระบบปิดปรับปรุง',
    Profile: 'โปรไฟล์',
    Level: 'เลเวล',
    PreAmount: 'กำลังออกจากโปรโมชั่น ยอดที่จะยึดกลับ',
    LanguageChangeMsg: 'เปลี่ยนภาษาจะรีโหลดใหม่',
    NoData: 'ไม่มีข้อมูล',
    AccumulatedWager: 'สะสมยอดเทิร์น',
    PromotionThreshold: 'เงื่อนไขโปรโมชั่น',
    Remaining1xWager: 'ยอดที่เหลือเทิร์นหนึ่งเท่า',
    Install: 'ดาวน์โหลด',
    PromptMsg: 'ดาวน์โหลดแอพพลิเคชันเราฟรี ไม่กินเนื้อที่ความจำ',
    SafariHint0: 'เปิดในซาฟารี',
    SafariHint1: 'กดที่',
    SafariHint2: 'เพิ่มไปที่หน้าจอโฮม',
    Locked: 'ล็อก',
    TimeRemaining: 'เวลาคงเหลือ',
    LeaderBoard: 'Leader Board',
    Daily: 'รายวัน',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    EndDate: 'วันที่สิ้นสุด',
    Create: 'สร้าง',
    GoBack: 'กลับ',
    SpecialOffer: 'โบนัสพิเศษ',
    MemberShipLevel: 'รายละเอียดเลเวล',
    Forever: 'ตลอดกาล',
    Year: 'ปี',
    DailyMissionReward: 'รางวัลภารกิจรายวัน',
    VIP2Info: 'ผูกข้อมูลสมาชิก',
    VIP3Info: 'ฝากครั้งแรก',
    VIP4Info: 'เดิมพันถึง {Amount} ภายใน2เดือน',
    VIP5Info: 'เดิมพันถึง {Amount} ภายใน2เดือน',
    VIP6Info: 'เดิมพันถึง {Amount} ภายใน2เดือน',
    VIPDepositInfo: 'ยอดฝากเกิน{Amount} ใน 2 เดือน',
    Activated: 'สำเร็จ',
    PlayGame: 'เล่นเกม',
    Reward: 'รางวัลภารกิจรายวัน',
    Passbook: 'ประวัติกิจกรรม',
    DepositRecord: 'ประวัติฝาก',
    WithdrawalRecord: 'ประวัติถอน',
    AgentCode: 'โค้ดผู้แนะนำ',
    GameAccount: 'บัญชีเกม',
    JoinPromotion: 'เข้าร่วมกิจกรรม',
    Spin: 'สปิน',
    Congratulation: 'ยินดีด้วย',
    TicketNotEnough: 'ตั๋วไม่เพียงพอ',
    RewardMsg: 'คุณได้รับ {num}',
    BounsWheel: 'วงล้อโชคลาภ',
    Yes: 'ใช่',
    No: 'ไม่',
    DiamondShop: 'ร้านค้าไดมอนด์',
    GameRecord: 'ประวัติเกม',
    Setting: 'ตั้งค่า',
    DiamondInsufficient: 'ยืนยันแลกเปลี่ยนหรือไม่',
    FullScreenMode: 'เข้าสู่โหมดเต็มจอ',
    RewardPromotion: 'โปรฯ',
    RebatePromotion: 'ค่าน้ำ',
    ReferrerPromotion: 'ชวนเพื่อน',
    DepositPromotion: 'ฝาก',
    LotteryPromotion: 'จับฉลาก',
    '1xTurnover': 'เทิร์นหนึ่งเท่า',
    AccumulatedWagerInfo: 'เทิร์นโอเวอร์สะสมปัจจุบัน ทำครบเงื่อนไขโปรจึงสามารถผ่านโปรได้ \n เงื่อนไขผ่านโปร：{max}',
    NoLimit: 'ไม่ จำกัด',
    TotalBet: 'ยอดเดิมพันทั้งหมด',
    TotalDeposit: 'ยอดฝากทั้งหมด',
    VIPPrivileges: 'สิทธิพิเศษVIP',
    Requirement: 'เงื่อนไขสำเร็จ',
    ReferralInfo: 'แนะนำเพื่อน',
    UserName: 'ยูสเซอร์',
    CompanyProfit: 'กำไรบริษัท',
    Bonus: 'ยอดเงินกิจกรรม',
    SystemRecycle: 'ยอดกิจกรรมที่ถูกยึดคืน',
    CurrentMonth: 'เดือนนี้',
    PreviousMonth: 'เดือนก่อน',
    FeedbackAmount: 'ยอดคืน',
    MinBetAmount: 'ยอดเดิมพันต่ำสุดต่อสปิน{Amount}',
    PromoCode: 'โค้ดโปรโมชั่น',
    DailyMissionRewardText: 'รับเพิ่ม {Percent}% รางวัลภารกิจรายวัน .',
    PrivatePolicy: 'นโยบายความเป็นส่วนตัว',
    VerifyInfo: 'ยืนยันตัวตน',
    Subscribe: 'ติดตามข่าวสาร',
    A2HS: 'เพิ่มไปหน้าโฮม',
    Introduction: 'ขั้นตอน',
    Next: 'ขั้นถัดไป',
    WagerLimit: 'จำกัดยอดเทิร์น',
    WagerLimitDesc: 'รวมยอดเทิร์น1เท่าและเทิร์นเงินโบนัสภารกิจรายวัน',
    NetwinWagerDesc: 'สะสมยอดชนะถึงกำหนดสามารถผ่านโปรได้\nเงื่อนไข：{max}',
    Quit: 'ออก',
    AskJoinAutoDepositPromotion: 'เข้าร่วมกิจกรรมฝากออโต้หรือไม่',
    AskCancelJoinedPromotion: 'ยกเลิกเข้าร่วมกิจกรรมฝากออโต้หรือไม',
    Netwin: 'แพ้ชนะ',
    ReferralCode: 'รหัสแนะนำ',
    ReferralQRCode: 'รหัส QR อ้างอิง',
    EnterReferralCode: 'กรอกรหัสแนะนำ',
    ReferralCodeDoesNotExist: 'รหัสแนะนำไม่มีอยู่จริง',
    ReferralByUser: 'แนะนำโดย {username}',
    NoPromotionItem: 'ไม่มีรายการที่ตรงตามเงื่อนไข'
  },
  CashOut: {
    Fields: {
      OrderNumber: 'หมายเลขออเดอร์',
      AuditAmount: 'ยอด',
      Status: 'สถานะ',
      CreateTime: 'เวลาสร้าง'
    }
  },
  CashIn: {
    Fields: {
      OrderNumber: 'หมายเลขออเดอร์',
      AuditAmount: 'ยอด',
      Status: 'สถานะ',
      CreateTime: 'เวลาสร้าง'
    }
  },
  Accounting: {
    Fields: {
      Source: 'ที่มา',
      Type: 'ประเภท',
      Quantity: 'จำนวน',
      CreateTime: 'เวลาสร้าง'
    },
    Type: {
      1: 'เหรียญทอง', // เหรียญทอง
      5: 'เพชร' // การ์ดสมบัติว่างเปล่า
    },
    Passbook: {
      NoviceActivityReward: 'โปรโมชั่นสมาชิกใหม่',
      ReferrerActivityReward: 'โปรโมชั่นแนะนำเพื่อน',
      PromotionActivityReward: 'โปรโมชั่นโบนัส',
      FeedbackActivityReward: 'โปรโมชั่นคืนค่าน้ำ',
      SystemReward: 'โปรโมชั่นโดยระบบ',
      OtherReward: 'โปรโมชั่นเพิ่มเติม',
      RecycleCategory: 'รีไซเคิลกิจกรรมโบนัส',
      DepositActivityReward: 'โบนัสเงินฝาก',
      DailyMissionReward: 'โบนัสภารกิจ',
      Lottery: 'รางวัลจับฉลาก',
      DiamondMall: 'ธุรกรรมร้านค้า',
      AbnormalOrderRecycle: 'ยึดเงินบิลผิดปกติ'
    }
  },
  BettingRecord: {
    Fields: {
      ProviderName: 'ชื่อผู้ให้บริการ',
      GameType: 'ประเภทเกม',
      Turnover: 'ยอดเทิร์นโอเวอร์',
      Netwin: 'ยอดแพ้ชนะ',
      BetTime: 'เวลาเดิมพัน'
    }
  },
  Message: {
    Msg: {
      HowToDeposit: 'ขั้นตอนการเติมเครดิต',
      PleaseUploadImage: 'กรุณาอัพโหลดรูป',
      AmountLessThen: 'Tห้ามต่ำกว่ายอด{Amount}',
      AmountGreaterThen: 'ห้ามสูงกว่ายอด{Amount}',
      AreYouPromotionFinished: 'ยืนยันที่จะสำเร็จหรือไม่',
      PleaseEnterCellPhone: 'กรุณากรอกหมายเลขเบอร์โทร',
      DoYouWantToWithdraw: 'คุณต้องการจะถอน {Amount} {CurrencyName} หรือไม่?',
      DoYouWantToDeposit: 'คุณต้องการจะฝาก {Amount} {CurrencyName} หรือไม่?',
      DoYouChangeLanguage: 'ต้องการเปลี่ยนภาษาหรือไม่',
      VersionUpdated: 'อัพเดตเวอร์ชั่น',
      VersionUpdatedMsg: 'อัพเดตเสร็จสิ้น รีเฟรชเกม',
      TokenExpired: 'Token หมดอายุ',
      TokenExpiredMsg: ' Token คุณหมดอายุ กรุณาล็อกอินใหม่',
      RepeatLogin: 'ล็อกอินใหม่',
      RepeatLoginMsg: 'ไอดีเข้าสู่ระบบซ้ำ กรุณาล็อกอินใหม่',
      PromotionCheckMsg: 'อยู่ระหว่างกิจกรรม ดำเนินการฝากต่อหรือไม่?',
      RelievePromotion: 'ปลดกิจกรรม',
      JoinDepositPromotionMsg: 'เข้าร่วมกิจกรรมโบนัสฝากหรือไม่?',
      AgentCodeHint: 'กรุณากรอกโค้ดผู้แนะนำหรือ: {code}@',
      AgentCodeHintG88: 'กรุณากรอกโค้ดผู้แนะนำหรือ: {code}@',
      AccountFormat: 'กรอกภาษาอังกฤษและตัวเลข6-12ตัว',
      PwdFormat: 'กรอกภาษาอังกฤษและตัวเลข6-12ตัว',
      ConfirmPwd: 'กรุณากรอกรหัสผ่านอีกครั้ง',
      ConfirmToChange: 'ยืนยันแลกเปลี่ยนหรือไม่?',
      OTPMsg: 'ส่งotpสำเร็จ',
      PromotionVIPLevel: 'VIP{level} ถึงสามารถเข้าร่วมกิจกรรม'
    },
    EnterAnyPress: 'ป้อนคีย์ใด ๆ',
    PleaseBindAccount: 'กรุณาผูกบัญชีก่อน',
    AutoDeposit: {
      1: 'ใช้บัญชีธนาคารที่ท่านลงทะเบียนไว้กับทางเราในการทำรายการ',
      2: 'ใช้ เอทีเอ็ม ,อินเตอร์เน็ตแบ้งกิ้ง หรือ โมบายแบ้งกิ้ง ในการทำรายการเท่านั้น',
      3: 'โอนเงินเข้าบัญชีด้านบน (ท่านสามารถกด ปุ่ม COPY เพื่อคัดลอกเบอร์บัญชีได้)',
      4: 'หลังจากทำรายการโอนแล้ว รอ1-3 นาทียอดเครดิตจะเข้าตัวท่านโดยไม่ต้องทำการแจ้งฝาก',
      5: 'กรุณาหลีกเลี่ยงการฝากเงิน กับ ธนาคาร KTB ในช่วงเวลา 23:00 น. – 03:00 น. เนื่องจาก ระบบฝาก-ถอน ออนไลน์ ของธนาคาร ปิดปรับปรุง จึงทำให้ทางเรา ไม่สามารถเช็คยอดเงินเข้าของลูกค้าได้',
      6: 'หากลูกค้าต้องการฝาก กรุณาติดต่อแอดมินสาวสวย เพื่อขอรับเลขบัญชี ก่อนการโอนในช่วงเวลาดังกล่าว',
      note1: 'กรณีใช้บัญชีอื่นที่ไม่ได้ลงทะเบียนไว้ เครดิตจะไม่เข้าไอดีของลูกค้าและไม่สามารถขอคืนยอดโอนได้',
      note2: 'สมาชิกที่ทำการฝากออโต้ครั้งแรก กรุณาไปที่ศูนย์สมาชิกกรอกข้อมูลส่วนตัวให้เรียบร้อย'
    },
    AutoDepositContent: `* ฝากขั้นต่ำ 20 บาท ถอนสูงสุด 100,000 บาทต่อวัน *

    1. ใช้บัญชีที่ลงทะเบียนในเว็บในการทำรายการเท่านั้น

    2. ใช้เอทีเอ็ม อินเตอร์เน็ตแบ้งกิ้งหรือโมบายแบ้งกิ้งในการฝากเท่านั้น

    3. โอนเงินเข้าบัญชีของเว็บ รอ 1-3 นาทียอดจะปรับอัตโนมัติ ไม่ต้องทำการแจ้งฝาก

    4. ห้ามฝากผ่านธนาคารกรุงไทยในช่วงเวลา 23:00 น. – 03:00 น. เนื่องจากระบบปิดปรับปรุง ระบบจะไม่สามารถเพิ่มเครดิตให้ในช่วงเวลานี้ได้หากฝากผ่านธนาคารกรุงไทย

    *กรณีใช้บัญชีอื่นที่ไม่ได้ลงทะเบียนไว้ เครดิตจะไม่เข้าไอดีของลูกค้าและไม่สามารถขอคืนยอดโอนได้ *

    สมาชิกที่ทำการฝากออโต้ครั้งแรก กรุณากรอกข้อมูลส่วนตัวให้เรียบร้อยโดยไปที่การตั้งค่าข้อมมูลสมาชิก`,
    BindAccountMsg: 'ยังไม่ผูกอุปกรณ์ หากออกจากระบบจะไม่สามารถฟื้นฟูบัญชีได้ ต้องการผูกอุปกรณ์หรือไม่?',
    PleaseEnterInSec: 'กรุณายืนยันภายใน {Sec} วินาที',
    PromotionReleaseTime: 'ปลดโปรต้องใช้เวลา3-5นาที',
    OTPPhoneNotSame: 'OTPไม่ตรงกับเบอร์โทร กรุณาขอเลขOTPใหม่อีกครั้ง'

  },
  Member: {
    Info: {
      ReferrerAccount: 'บัญชีผู้อ้างอิง'
    }
  },
  Setting: {
    Title: 'การตั้งค่า'
  },
  Inbox: {
    tab: {
      System: 'ระบบ',
      Promotion: 'กิจกรรม',
      Rewards: 'รางวัล'
    }
  },
  Cash: {
    Status: {
      0: 'สร้างออเดอร์', // สร้างออเดอร์
      1: 'อยู่ระหว่างตรวจทาน', // ระหว่างตรวจทาน
      2: 'การตรวจสอบที่สำเร็จ', // การตรวจสอบที่สำเร็จ
      3: 'รีวิวถูกปฏิเสธ', // รีวิวถูกปฏิเสธ
      4: 'ยกเลิกธุรกรรม' // ยกเลิกธุรกรรม
    }
  },
  SystemMail: {
    tab: {
      System: 'ระบบ',
      Promotion: 'กิจกรรม',
      Rewards: 'รางวัล'
    }
  },
  // ------------------------ 第三方金流 ---------------------------
  ThirdPartPayment: {
    MinimumDeposit: 'ถอนน้อยสุดต่อครั้ง',
    MaximumDeposit: 'ฝากมากสุดต่อครั้ง',
    DailyLimit: 'ลิมิตต่อวัน',
    HandingFee: 'ค่าบริการ',
    NoHotKey: 'ช่องการชำระเงินนี้ไม่สามารถเข้าร่วมโปรโมชั่นได้',
    Methods: {
      BANK: 'Bank Transfer',
      BANK_QRCODE: 'Prompt Pay',
      MOMO: 'MOMO PAY',
      ZALO: 'ZALO PAY',
      TNG: "Touch'n Go",
      WAVE_MONEY_TRANSFER: 'WaveMoney',
      DUITNOW_QRCODE: 'DuitNow',
      TRUE_MONEY_WALLET: 'TrueMoney',
      GCASH: 'GCash',
      GRABPAY: 'GrabPAY',
      ManualDeposit: 'ฝาก'
    },
    Fields: {
      Channel: 'แชนแนล',
      Name: 'ชื่อ',
      Phone: 'เบอร์โทร',
      TransactionID: 'ไอดีธุรกรรม',
      BankName: 'ชื่อธนาคาร',
      BankAccount: 'เลขบัญชีธนาคาร',
      BankList: 'เลือกธนาคาร'
    }
  },
  DailyMission: {
    Mission: {
      Bet: '{Threshold} ยอดเดิมพันที่เป็นผล',
      Win: '{Threshold} ยอดแพ้ชนะ',
      BetTimes: '{Threshold} จำนวนสปิน',
      DepositAmount: 'ยอดฝาก {Threshold} บาท',
      Referrer: '{Threshold} ผู้แนะนำ',
      AllGames: 'บนเกมทั้งหมด',
      MultiGames: 'บนหลากเกม',
      DailyLogin: 'ล็อกอินรายวัน'
    }
  },
  GameType: {
    'Slot Game': 'สล็อต',
    'Table Game': 'Table',
    'Card Game': 'Card',
    'Arcade Game Fisher': 'ยิงปลา',
    'Arcade Game': 'อาร์เคด',
    'Live Game': 'Live'
  }
}
