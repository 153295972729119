import en from './en'
import th from './th'
import my from './my'
import vi from './vi'
import cn from './zh-CN'
export default {
  en,
  th,
  my,
  vi,
  'zh-CN': cn
}