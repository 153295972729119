import CONSTANT_GAME from '../../constant/game'

const state = {
  language: '',
  isGameView: false,
  version: '0.0.1',
  LocationID: 0,
  LocationCode: 'th',
  IsLoading: false,
  isBGMEnable: true,
  prevPage: null,
  favGames: [],
  specialOfferList: [],
  isAlertShow: false,
  isMaintainence: false,
  MaintainContent: '',
  Domains: {},
  ProviderList: [],
  SocketVersion: '',
  GameFilter: {
    gameType: null,
    provider: 'top50',
    providerId: null
  },
  isOpenGameWallet: false,
  launchGame: {
    html: null,
    url: null,
    type: CONSTANT_GAME.launch.type.HTML
  }
}

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language
  },
  SET_VERSION: (state, version) => {
    state.version = version
  },
  SET_GAMEVIEW: (state, isGameView) => {
    state.isGameView = isGameView
  },
  SET_LOCATIONID: (state, LocationID) => {
    state.LocationID = LocationID
  },
  SET_ISLOADING: (state, IsLoading) => {
    state.IsLoading = IsLoading
  },
  SET_ISBGMENABLE: (state, enable) => {
    state.isBGMEnable = enable
  },
  SET_PREVPAGE: (state, page) => {
    state.prevPage = page
  },
  SET_FAVGAMES: (state, game) => {
    state.favGames = game
  },
  SET_SPECIALOFFERLIST: (state, list) => {
    state.specialOfferList = list
  },
  SET_ISALERTSHOW: (state, bool) => {
    state.isAlertShow = bool
  },
  SET_ISMAINTAINENCE: (state, bool) => {
    state.isMaintainence = bool
  },
  SET_MAINTAINCONTENT: (state, text) => {
    state.MaintainContent = text
  },
  SET_DOMAINS: (state, data) => {
    state.Domains = data
  },
  SET_PROVIDERLIST: (state, data) => {
    state.ProviderList = data
  },
  SET_SOCKETVERSION: (state, data) => {
    state.SocketVersion = data
  },
  SET_GAMEFILTER: (state, data) => {
    state.GameFilter = data
  },
  SET_ISOPENGAMEWALLET: (state, data) => {
    state.isOpenGameWallet = data
  },
  SET_LOCATIONCODE: (state, data) => {
    state.LocationCode = data
  },
  SET_LAUNCH_GAME: (state, data) => {
    state.launchGame.type = data.GameHtml ? CONSTANT_GAME.launch.type.HTML : CONSTANT_GAME.launch.type.URL
    state.launchGame.html = data.GameHtml ?? null
    state.launchGame.url = data.GameUrl?.replace(/http:\/\//g, 'https://') ?? null
  }
}

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setGameView({ commit }, isGameView) {
    commit('SET_GAMEVIEW', isGameView)
  },
  setVersion({ commit }, version) {
    commit('SET_VERSION', version)
  },
  setLocationID({ commit }, LocationID) {
    commit('SET_LOCATIONID', LocationID)
  },
  setLoading({ commit }, isLoading) {
    commit('SET_ISLOADING', isLoading)
  },
  setBGMEnable({ commit }, enable) {
    commit('SET_ISBGMENABLE', enable)
  },
  setPrevPage({ commit }, page) {
    commit('SET_PREVPAGE', page)
  },
  setFavGame({ commit }, game) {
    commit('SET_FAVGAMES', game)
  },
  setSpecialOfferList({ commit }, list) {
    commit('SET_SPECIALOFFERLIST', list)
  },
  setIsAlertShow({ commit }, bool) {
    commit('SET_ISALERTSHOW', bool)
  },
  setIsMaintainence({ commit }, bool) {
    commit('SET_ISMAINTAINENCE', bool)
  },
  setMaintainContent({ commit }, text) {
    commit('SET_MAINTAINCONTENT', text)
  },
  setDomains({ commit }, data) {
    commit('SET_DOMAINS', data)
  },
  setProviderList({ commit }, data) {
    commit('SET_PROVIDERLIST', data)
  },
  setSocketVersion({ commit }, data) {
    commit('SET_SOCKETVERSION', data)
  },
  setGameFilter({ commit }, data) {
    commit('SET_GAMEFILTER', data)
  },
  setIsOpenGameWallet({ commit }, data) {
    commit('SET_ISOPENGAMEWALLET', data)
  },
  setLocationCode({ commit }, data) {
    commit('SET_LOCATIONCODE', data)
  },
  setLaunchGame({ commit }, data) {
    commit('SET_LAUNCH_GAME', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
