const state = {
  count: 180,
  lock: false,
  tempData: null,
  firstClick: false
}

const mutations = {
  SET_TIMER_COUNT: (state, count) => {
    state.count = count
  },
  SET_TIMER_LOCK: (state, lock) => {
    state.lock = lock
  },
  SET_TIMER_TEMP_DATA: (state, data) => {
    state.tempData = data
  },
  SET_FIRST_CLICK: (state, firstClick) => {
    state.firstClick = firstClick
  }
}

const actions = {
  setTimerCount({ commit }, count) {
    commit('SET_TIMER_COUNT', count)
  },
  setTimerLock({ commit }, lock) {
    commit('SET_TIMER_LOCK', lock)
  },
  setTimerTempData({ commit }, Data) {
    commit('SET_TIMER_TEMP_DATA', Data)
  },
  setFirstClick({ commit }, firstClick) {
    commit('SET_FIRST_CLICK', firstClick)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
